import { Component, ViewChild, inject } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { IntegrationDetailsComponent } from '../integration-details/integration-details.component';
import { IntegrationProfileService } from '../../core/services/integration-profile.service';
import { lastValueFrom } from 'rxjs';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { IResponsePage } from '../../core/models/IResponse';
import { IIntegration } from '../../core/models/IIntegration';
import { generalParams } from '../../core/models/Params';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from '../../core/services/notification.service';
import { TasksService } from '../../core/services/tasks.service';
import { AuthService } from '../../core/services/auth.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { FilterGroupService } from '../../core/services/filter-group.service';

@Component({
  selector: 'app-integrations-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    LoaderComponent,
    CommonModule,
  ],
  providers: [provideNoopAnimations()],
  templateUrl: './integrations-list.component.html',
  styleUrl: './integrations-list.component.scss',
})
export class IntegrationsListComponent {
  @ViewChild('MatPaginator') paginator!: MatPaginator;
  readonly integrationProfileDataService = inject(
    IntegrationProfileDataService
  );
  readonly integrationProfileService = inject(IntegrationProfileService);
  readonly notificationService = inject(NotificationService);
  readonly tasksService = inject(TasksService);
  readonly authService = inject(AuthService);
  readonly filterGroupService = inject(FilterGroupService);
  readonly ActiveEnvironment: string = environment.production ? 'PROD' : 'DEV';

  displayedColumns: string[] = [
    'actions',
    'id',
    'customer.id',
    'customer.name',
    'type.name',
    'integration_type',
    'integration_pms.name',
    'pms',
    'connection_type',
    'seasonal_flag',
    'flag_alerts_active',
    'alert_days',
    'survey_types_default',
    // 'url',
    // 'user',
    // 'password',
    // 'ftp_path',
    // 'pms',
    // 'forced_integration_quality',
    // 'mapper_separator',
    // 'provider_id',
    // 'mapper_file_extension',
    // 'obtain_data',
    // 'disable_filter_notification',
    // 'type.id',
    // 'flag_filter_domains',
    // 'open_date',
    // 'close_date',
    // 'excluded_domains',
    // 'notes',
  ];
  dataSource = new MatTableDataSource<IIntegration>();
  integrationProfileData: any;
  params = {
    ...generalParams,
    filter: '',
  };
  readonly dialogDetailsConf = {
    panelClass: 'custom-dialog-container',
    disableClose: true,
  };
  loading: boolean = false;
  totalOfElements: number = 0;
  integrations$: any;

  constructor(private dialog: MatDialog) {}

  async ngOnInit() {
    await this.updateRecords(this.params);
    this.integrations$ =
      this.integrationProfileDataService.integrations$.subscribe(
        (integrations) => {
          this.dataSource.data = integrations;
        }
      );
  }

  private async verifyFilters(params: {
    page: number;
    size: number;
    sort_dir: string;
    sort: string;
    deleted: boolean;
    integration_id?: number;
  }) {
    this.loading = true;
    const filterGroups$ = this.filterGroupService.searchProfileGroup(params);
    const { data } = (await lastValueFrom(filterGroups$)) as any;
    const existFilters = !!data.length;
    this.loading = false;
    return existFilters;
  }

  private async updateRecords(params: {
    page: number;
    size: number;
    sort_dir: string;
    sort: string;
    filter?: string;
  }) {
    this.loading = true;
    try {
      const integrationProfiles$ =
        this.integrationProfileService.getIntegrationProfiles(params);
      const {
        data: { info, pageNumber, totalOfElements },
      } = (await lastValueFrom(integrationProfiles$)) as IResponsePage;
      this.dataSource = new MatTableDataSource<IIntegration>(info);
      this.paginator.length = totalOfElements;
      this.integrationProfileDataService.initializeIntegrations(info);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.nextPageLabel = '';
    this.paginator._intl.previousPageLabel = '';
    this.paginator._intl.lastPageLabel = '';
    this.paginator._intl.firstPageLabel = '';
  }

  applyFilter(input: HTMLInputElement, event?: any) {
    event?.preventDefault();
    const filterValue = input.value.trim().toLowerCase();
    this.params = {
      ...this.params,
      page: 0,
      filter: filterValue,
    };
    this.updateRecords(this.params);
  }

  openIntegrationDetails() {
    this.dialog.open(IntegrationDetailsComponent, this.dialogDetailsConf);
  }

  onPageChange($event: PageEvent) {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;

    this.params = {
      ...this.params,
      page: pageIndex,
      size: pageSize,
    };

    this.updateRecords(this.params);
  }

  edit(element: any, event?: any) {
    const target = event?.target as HTMLElement;
    if (target?.closest('.action-button')) {
      return;
    }

    this.dialog.open(IntegrationDetailsComponent, {
      ...this.dialogDetailsConf,
      data: element,
    });
  }

  async run(integration: IIntegration) {
    if (!integration.id) return;

    const existFilters = await this.verifyFilters({
      ...generalParams,
      integration_id: integration.id,
      deleted: false,
      size: 3000,
    });

    if (!existFilters) {
      const resp$ = this.dialog
        .open(ConfirmationDialogComponent, {
          data: 'There are not filters, are you sure run this task?',
        })
        .afterClosed();
      const resp = await lastValueFrom(resp$);

      if (!resp) return;
    }

    const resp = await this.notificationService.showInputDialog();

    if (!resp) return;

    this.loading = true;

    try {
      const taskResponse$ = this.tasksService.getRule(integration.id);
      const { data: dataTask } =
        ((await lastValueFrom(taskResponse$)) as any) ?? {};
      if (!dataTask) {
        this.notificationService.showResultDialog('No Task Found');
        return;
      }

      const { days_from_checkin } = resp;
      const taskRun$ = this.tasksService.runRule(integration.id, {
        days_from_checkin,
      });
      const result = (await lastValueFrom(taskRun$)) as any;
      this.notificationService.showResultDialog(
        JSON.stringify(result, null, 2)
      );
      this.notificationService.showSuccessNotification();
    } catch (error) {
      this.notificationService.showErrorNotification();
    } finally {
      this.loading = false;
    }
  }

  async logout() {
    const resp$ = this.dialog
      .open(ConfirmationDialogComponent, {
        data: 'Are you sure you want to log out?',
      })
      .afterClosed();
    const resp = await lastValueFrom(resp$);

    if (!resp) return;

    this.authService.logout();
  }
}
