import { Component, Input, effect, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ContactService } from '../../core/services/contacts.service';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ContactDetailsComponent } from '../contact-details/contact-details.component';
import { generalParams } from '../../core/models/Params';
import { NotificationService } from '../../core/services/notification.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatInputModule, MatTableModule],
  providers: [ContactService],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
  displayedColumns: any = [
    'actions',
    'priority',
    'first_name',
    'last_name',
    'email',
    'company_position',
  ];
  readonly contactService = inject(ContactService);
  notificationService = inject(NotificationService);
  integrationProfileDataService = inject(IntegrationProfileDataService);
  @Input() customer_id: number | null | undefined = null;
  readonly contactModalConfig = {
    disableClose: true,
    panelClass: 'dialog-content-container',
  };
  searchParams: any;

  constructor(private dialog: MatDialog) {
    effect(() => {
      this.customer_id =
        this.integrationProfileDataService.selectedIntegration()?.customer
          ?.id ?? this.customer_id;
      this.searchParams = {
        ...this.searchParams,
        customer_id: this.customer_id,
      };
    });
  }

  ngOnInit(): void {
    this.searchParams = {
      ...generalParams,
      size: 3000,
      deleted: false,
      customer_id: this.customer_id,
    };
    if (!this.customer_id) return;
    this.updateRecords({ ...this.searchParams });
  }

  openDetails() {
    this.dialog
      .open(ContactDetailsComponent, {
        data: {
          customer_id: this.customer_id,
          action: 'Create',
        },
        ...this.contactModalConfig,
      })
      .afterClosed()
      .subscribe(() => {
        if (this.customer_id) this.updateRecords({ ...this.searchParams });
      });
  }
  async deleteElement(contact: any) {
    const resp$ = this.dialog
      .open(ConfirmationDialogComponent, {
        data: 'Are you sure you want to delete this?',
      })
      .afterClosed();
    const resp = await lastValueFrom(resp$);

    if (!resp) return;

    try {
      this.notificationService.startLoading();
      const deleteContact$ = this.contactService.deleteContact(contact.id);
      const { data } = (await lastValueFrom(deleteContact$)) as any;
      await this.updateRecords({ ...this.searchParams });
      this.notificationService.showSuccessNotification();
    } catch (error) {
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }
  edit(contact: any, event?: any) {
    const target = event?.target as HTMLElement;
    if (target?.closest('.action-button')) {
      return;
    }

    this.dialog
      .open(ContactDetailsComponent, {
        data: {
          contact,
          customer_id: this.customer_id,
          action: 'Update',
        },
        ...this.contactModalConfig,
      })
      .afterClosed()
      .subscribe(() => {
        if (this.customer_id) this.updateRecords({ ...this.searchParams });
      });
  }
  dataSource = new MatTableDataSource();

  private async updateRecords(params: {
    page: number;
    size: number;
    sort_dir: string;
    deleted: boolean;
    sort: string;
    customer_id?: number;
  }) {
    try {
      this.notificationService.startLoading();
      const contactService$ = this.contactService.searchContacts(params);
      const { data } = (await lastValueFrom(contactService$)) as any;
      this.dataSource.data = data ?? [];
    } catch (error) {
    } finally {
      this.notificationService.stopLoading();
    }
  }
}
