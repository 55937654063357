<div class="w-full flex flex-wrap p-3 max-w-4xl mx-auto mt-3">
    <div class="w-full md:w-3/4 mb-4 md:mb-0">
        <label class="block mb-2 text-sm font-medium text-gray-900">Frecuency (In hours)</label>
        <input required [(ngModel)]="frecuency_hours" type="number" id="task-name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Frecuency" required />
    </div>

    <div class="w-full md:w-1/4 flex flex-col items-center justify-center mb-5">
        <label class="inline-flex flex-col items-center cursor-pointer">
            <span class="mb-2 text-sm font-medium text-gray-900">Enabled</span>
            <input [(ngModel)]="enabled" type="checkbox" value="" class="sr-only peer" ng-required="true"
                id="task-enabled">
            <div
                class="relative w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-current">
            </div>
        </label>
    </div>

    <div class="w-full md:w-5/12 mb-4 md:mb-0 mr-5 mt-5">
        <label class="block mb-2 text-sm font-medium text-gray-900">Name</label>
        <input [(ngModel)]="name" type="text" disabled
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Name" required />
    </div>

    <div class="w-full md:w-5/12 mb-4 md:mb-0 mt-5">
        <label class="block mb-2 text-sm font-medium text-gray-900">Description</label>
        <input [(ngModel)]="description" type="text" disabled
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Description" required />
    </div>





    <div class="flex flex-col sm:flex-row justify-between w-full pt-5">

        <button (click)="run()" type="submit" [disabled]="!name" [ngClass]="{'!bg-gray-400':!name}" id="task-run-btn"
            class="mx-2 mb-2 sm:mb-0 text-white bg-green-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Run</button>


        <div class="flex flex-col sm:flex-row sm:ml-auto">

            <button type="submit" (click)="save()" id="task-save-btn"
                class="mx-2 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
        </div>
    </div>

</div>