import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomerFidelityService {
  readonly url = environment.fidelityUrl + 'customers/';

  constructor(private http: HttpClient) {}

  findByWord(params: any) {
    let formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    return this.http.get(`${this.url}find/`, { params });
  }
}
