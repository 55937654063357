import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { IIntegration } from '../models/IIntegration';

@Injectable({
  providedIn: 'root',
})
export class IntegrationProfileDataService {
  private integrationsSubject: BehaviorSubject<IIntegration[]>;
  public integrations$: Observable<IIntegration[]>;

  selectedIntegration = signal<IIntegration | null>(null);

  constructor() {
    this.integrationsSubject = new BehaviorSubject<IIntegration[]>([]);
    this.integrations$ = this.integrationsSubject.asObservable();
  }

  setSelectedIntegration(integration: IIntegration) {
    this.selectedIntegration.set(integration);
  }

  clearSelectedIntegration() {
    this.selectedIntegration.set(null);
  }

  initializeIntegrations(initialIntegrations: IIntegration[]): void {
    this.integrationsSubject.next(initialIntegrations);
  }

  get integrations(): IIntegration[] {
    return this.integrationsSubject.value;
  }

  async createIntegration(newIntegration: IIntegration): Promise<void> {
    const updatedIntegrations = [...this.integrations, newIntegration];
    this.integrationsSubject.next(updatedIntegrations);
    await lastValueFrom(this.integrations$);
  }

  async updateIntegration(updatedIntegration: IIntegration): Promise<void> {
    const updatedIntegrations = this.integrations.map((integration) =>
      integration.id === updatedIntegration.id
        ? updatedIntegration
        : integration
    );
    this.integrationsSubject.next(updatedIntegrations);
    await lastValueFrom(this.integrations$);
  }

  async deleteIntegration(integrationId: number): Promise<void> {
    const updatedIntegrations = this.integrations.filter(
      (integration) => integration.id !== integrationId
    );
    this.integrationsSubject.next(updatedIntegrations);
    await lastValueFrom(this.integrations$);
  }
}
