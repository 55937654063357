@if (loading) {
<app-loader></app-loader>
}

<form onkeydown="return event.key != 'Enter';" [formGroup]="form"
    class="max-w-4xl mx-auto bg-white p-5 rounded-lg shadow-lg">
    <div class="grid gap-4 mb-6 md:grid-cols-3">


        <div class="flex flex-wrap md:col-span-3 custom-form-field-container">
            <label class="block mb-2 text-sm font-medium text-gray-900">Customer</label>
            <mat-form-field class="w-full h-10" id="customer-autocomplete">
                <input onfocus="this.select()" type="text" aria-label="Number" matInput formControlName="customer"
                    [matAutocomplete]="autoCustomer">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete autoActiveFirstOption #autoCustomer="matAutocomplete"
                    [displayWith]="displayFn.bind(this)">
                    @for (option of filteredOptionsCustomer | async; track option) {
                    <mat-option [value]="option">{{ option.id }} - {{ option.name }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </div>


        <div class="flex flex-wrap custom-form-field-container">
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Type</label>
                <mat-icon matTooltip="Tipo de integración dependiendo del PMS"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <mat-form-field class="w-full h-10" id="typeAutocomplete">
                <input onfocus="this.select()" type="text" aria-label="Number" matInput formControlName="type"
                    [matAutocomplete]="autoType">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete autoActiveFirstOption #autoType="matAutocomplete"
                    [displayWith]="displayFn.bind(this)">
                    @for (option of filteredOptionsType | async; track option) {
                    <mat-option [value]="option">{{ option.id }} - {{ option.name }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="flex flex-wrap custom-form-field-container">
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">PMS</label>
                <mat-icon matTooltip="PMS que utiliza el hotel"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <mat-form-field class="w-full h-10" id="pmsAutocomplete">
                <input onfocus="this.select()" type="text" aria-label="Number" matInput
                    formControlName="integration_pms" [matAutocomplete]="autoPms">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete autoActiveFirstOption #autoPms="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    @for (option of filteredOptiomsPms | async; track option) {
                    <mat-option [value]="option">{{ option.id }} - {{ option.name }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Alert Days</label>
            </div>

            <input id="alert_days" required type="number" formControlName="alert_days"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Alert Days" />
        </div>


        <div class="flex">
            <div class="flex flex-col items-center justify-center px-5">
                <label class="inline-flex flex-col items-center cursor-pointer">
                    <span class="mb-2 text-sm font-medium text-gray-900">Flag Alerts</span>
                    <input id="flag_alerts" type="checkbox" class="sr-only peer" formControlName="flag_alerts_active">
                    <div
                        class="relative w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-current">
                    </div>
                </label>
            </div>
            <div class="flex flex-col items-center justify-center px-5">
                <label class="inline-flex flex-col items-center cursor-pointer">
                    <span class="mb-2 text-sm font-medium text-gray-900">Seasonal</span>
                    <input id="flag_seasonal" type="checkbox" class="sr-only peer" formControlName="seasonal_flag">
                    <div
                        class="relative w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-current">
                    </div>
                </label>
            </div>
        </div>

        <div class="custom-form-field-container">
            <label class="block mb-2 text-sm font-medium text-gray-900">Open</label>
            <mat-form-field class="w-full h-10" id="open-date">
                <input readonly matInput [matDatepicker]="datePickerOpen" formControlName="open_date" placeholder="Open"
                    class="mt-1 p-2 w-full h-full rounded-md border-gray-300 focus:ring-opacity-50">
                <mat-datepicker-toggle matSuffix [for]="datePickerOpen"></mat-datepicker-toggle>
                <mat-datepicker #datePickerOpen></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="custom-form-field-container">
            <label class="block mb-2 text-sm font-medium text-gray-900">Close</label>
            <mat-form-field class="w-full h-10" id="close-date">
                <input readonly matInput [matDatepicker]="datePickerClose" formControlName="close_date"
                    placeholder="Close" class="mt-1 p-2 w-full h-full rounded-md border-gray-300 focus:ring-opacity-50">
                <mat-datepicker-toggle matSuffix [for]="datePickerClose"></mat-datepicker-toggle>
                <mat-datepicker #datePickerClose></mat-datepicker>
            </mat-form-field>
        </div>


        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">URL</label>
                <mat-icon matTooltip="URL del servidor FTP" class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="url" type="text" formControlName="url"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="URL" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">User</label>
                <mat-icon matTooltip="User para conectarse al servidor FTP"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="user" type="text" formControlName="user"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="User" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Password</label>
                <mat-icon matTooltip="Password para conectarse al servidor FTP"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="password" formControlName="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Password" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">FTP Path</label>
                <mat-icon matTooltip="Directorio interno donde se encuentran los archivos FTP"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="ftp" type="text" formControlName="ftp_path"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="FTP Path" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Email From</label>
                <mat-icon matTooltip="Filtro de remitente para una integración de tipo EMAIL"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="email_filter" type="text" formControlName="email_filter"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Email From" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Email Subject</label>
                <mat-icon matTooltip="Subject de email para una integración de tipo EMAIL "
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="email_subject" type="text" formControlName="email_subject"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Email Subject" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">File Name</label>
                <mat-icon matTooltip="Nombre o parte del nombre de los archivos FTP/EMAIL"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="file_name" type="text" formControlName="file_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="File Name" />
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">File Extension</label>
                <mat-icon matTooltip="Extensión del archivo FTP/EMAIL"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <select id="file_extension" formControlName="mapper_file_extension"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option [value]=""></option>
                @for (file of fileExtensions; track $index) {
                <option [value]="file">{{file}}</option>
                }
            </select>
        </div>
        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">File Separator</label>
                <mat-icon matTooltip="Caracter separador utilizado en el archivos FTP / EMAIL"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <select id="separator" formControlName="mapper_separator"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option [value]=""></option>
                @for (separator of separators; track $index) {
                <option [value]="separator">{{separator}}</option>
                }
            </select>
        </div>


        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">File Charset</label>
                <mat-icon
                    matTooltip="Charset específico de archivos de una integración de tipo FTP/EMAL (Solo seleccionar en excepciones)"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <select id="file_charset" formControlName="file_charset"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option [value]=""></option>
                @for (file of fileCharsets; track $index) {
                <option [value]="file">{{file}}</option>
                }
            </select>
        </div>

        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">File First Row</label>
                <mat-icon
                    matTooltip="Indica desde qué fila se debe leer el archivo para una integración de tipo FTP/EMAIL (0 = a la primer fila)"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="file_first_row" type="number" formControlName="file_first_row"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="File First Row" />
        </div>

        <div class="flex flex-col  justify-center">
            <label class="inline-flex flex-col  cursor-pointer">


                <div class="flex items-end pb-2 justify-between w-full">
                    <span class="text-sm font-medium text-gray-900">Exclude Last File Read</span>
                    <mat-icon
                        matTooltip="Especifica si al procesar archivos se debe excluir el ultimo leído o no, hay casos que siempre se actualiza un único archivo (Por defecto activo)"
                        class="cursor-pointer text-primary text-xl ml-2">info</mat-icon>
                </div>

                <input id="flag_alerts" type="checkbox" class="sr-only peer" formControlName="exclude_last_file_read">
                <div
                    class="relative w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-current">
                </div>
            </label>
        </div>

        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Surveys</label>
                <mat-icon
                    matTooltip="Tipos de encuesta por defecto para la integración en caso de no tener al menos un filtro configurado (1,2 para la mayoria de casos)."
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>
            <input id="old-surveys" type="text" formControlName="survey_types_default"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Surveys" />
        </div>

        <div>
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Quality</label>
                <mat-icon
                    matTooltip=" Se utiliza para forzar la calidad de la integración (debería estar vacío salvo algunas excepciones)"
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>

            <select id="integration-quality" formControlName="forced_integration_quality"
                class="custom-select bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option [value]=""></option>
                @for (quality of forced_integration_qualitys; track $index) {
                <option [value]="quality">{{quality}}</option>
                }
            </select>
        </div>

        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Connection</label>
            <input id="connection" type="text" formControlName="connection_type"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Connection" />
        </div>





        <div class="md:col-span-3">
            <div class="flex">
                <div class="flex items-end p-1 w-full">
                    <label class="text-sm font-medium text-gray-900 mr-1">Obtain Data</label>
                    <mat-icon matTooltip="JSON con informacion de conexión para una integración de tipo WS"
                        class="cursor-pointer text-primary text-xl">info</mat-icon>
                </div>
                <a href="https://docs.google.com/document/d/1yWM_gLdg2HWnmSkXfpbRc42C3VtFRbq0WZZEc0SP9zg/edit"
                    class="ml-auto text-blue-500 hover:underline whitespace-nowrap" target="_blank"
                    rel="noopener noreferrer">Obtain
                    Data
                    por WS</a>
            </div>
            <app-json-viewer #jsonViewer [height]="100" [jsonData]="jsonData"
                (jsonValid)="onJsonValid($event)"></app-json-viewer>
        </div>

        <div class="md:col-span-3">
            <label class="block mb-2 text-sm font-medium text-gray-900">Comments</label>
            <textarea id="old-notes" formControlName="notes"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                rows="4" placeholder="Additional information..."></textarea>
        </div>
    </div>


    <div class="mb-2 p-3 rounded-lg bg-gray-50">
        <p class="text-sm text-gray-500 !mb-0"><strong class="font-medium text-gray-800">Others</strong></p>
    </div>

    <div class="grid gap-4 mb-6 md:grid-cols-3">
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">PMS</label>
            <input id="old-pms" type="text" formControlName="pms"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="PMS" />
        </div>
        <div class="flex flex-col items-center justify-center">
            <label class="inline-flex flex-col items-center cursor-pointer">
                <span class="mb-2 text-sm font-medium text-gray-900">Domain filter</span>
                <input id="old-flag-domains" type="checkbox" value="" class="sr-only peer"
                    formControlName="flag_filter_domains">
                <div
                    class="relative w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-current">
                </div>
            </label>
        </div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Domains</label>
            <input id="old-domains" type="text" formControlName="excluded_domains"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Domains" />
        </div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Integration Type</label>
            <input id="old-type" type="text" formControlName="integration_type"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Integration Type" />
        </div>


    </div>

    <div class="flex justify-end dialog-footer">
        <button type="submit" id="save-button-integration" (click)="onSubmit()"
            class="mx-2 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
    </div>
</form>