import {
  Component,
  HostListener,
  Inject,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NotificationService } from '../../core/services/notification.service';
import { FilterGroupService } from '../../core/services/filter-group.service';
import { lastValueFrom } from 'rxjs';
import { FormsDataService } from '../../core/services/forms-data.service';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { JsonViewerComponent } from '../../shared/components/json-viewer/json-viewer.component';

@Component({
  selector: 'app-filter-details',
  standalone: true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    LoaderComponent,
    JsonViewerComponent,
  ],
  templateUrl: './filter-details.component.html',
  styleUrl: './filter-details.component.scss',
})
export class FilterDetailsComponent {
  form: FormGroup;
  readonly notificationService = inject(NotificationService);
  filterGroupService = inject(FilterGroupService);
  FormsDataService = inject(FormsDataService);
  filterData;
  readonly integration_id;
  readonly action: string;
  loading: boolean = false;
  jsonData: any;
  invalidJson: boolean = false;
  @ViewChild('jsonViewer') jsonViewer!: JsonViewerComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilterDetailsComponent>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      id: [null],
      name: [''],
      integration_id: [null],
      exclude: [false],
      deleted: [false],
      filters: [''],
      survey_types: ['1,2'],
    });
    this.filterData = data?.filter;
    this.integration_id = data?.integration_id;
    this.action = data?.action;
  }

  ngOnInit(): void {
    if (this.filterData) this.initializeFilter();
  }

  updateJson() {
    this.jsonViewer.validateJson();
  }

  onJsonValid(updatedJson: any) {
    if (updatedJson === 'jsonError') {
      this.invalidJson = true;
    } else {
      this.invalidJson = false;
    }
    this.form.get('filters')?.setValue(JSON.stringify(updatedJson, null, 2));
  }

  patchValues(data: any) {
    this.jsonData = [...data.filters];
    this.form.patchValue({
      ...data,
      survey_types: data?.survey_types
        .map((survey: any) => survey.id)
        .join(','),
      filters: JSON.stringify(data?.filters, null, 2),
    });
  }

  async initializeFilter() {
    try {
      this.loading = true;
      const filter$ = this.filterGroupService.getFilterGroup(
        this.filterData?.id
      );
      const { data } = (await lastValueFrom(filter$)) as any;
      this.filterData = data;
      this.jsonData = this.filterData?.filters;
      this.patchValues(this.filterData);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  closeDialog() {
    this.notificationService.closeDialog(
      this.dialogRef,
      "Are you sure you want to close this view? Your changes won't be saved "
    );
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeydown(): void {
    this.closeDialog();
  }

  save() {
    this.updateJson();
    if (!this.form.valid) {
      this.FormsDataService.showFormErrors(this.form);
      return;
    }
    if (this.invalidJson) return;
    if (this.filterData) {
      this.updateFilter();
    } else {
      this.createrFilter();
    }
  }
  async createrFilter() {
    try {
      this.notificationService.startLoading();
      const surveysIds = (this.form.get('survey_types')?.value as string).split(
        ','
      );
      const createFilter$ = this.filterGroupService.createFilterGroup({
        ...this.form.value,
        integration_id: this.integration_id,
        filters: JSON.parse(this.form.get('filters')?.value),
        survey_types: surveysIds.map((number: any) => ({ id: number })),
      });
      this.loading = true;
      const { data } = (await lastValueFrom(createFilter$)) as any;
      this.notificationService.showSuccessNotification();
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.loading = false;
      this.notificationService.stopLoading();
    }
  }
  async updateFilter() {
    try {
      this.notificationService.startLoading();
      const surveysIds = (this.form.get('survey_types')?.value as string).split(
        ','
      );
      const updateFilter$ = this.filterGroupService.updateFilterGroup(
        this.filterData.id,
        {
          ...this.form.value,
          filters: JSON.parse(this.form.get('filters')?.value),
          survey_types: surveysIds.map((number: any) => ({ id: number })),
        }
      );
      this.loading = true;
      const { data } = (await lastValueFrom(updateFilter$)) as any;
      this.notificationService.showSuccessNotification();
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.loading = false;
      this.notificationService.stopLoading();
    }
  }
}
