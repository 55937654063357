import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getContact(contact_id: number) {
    return this.http.get(`${this.url}/integration-contacts/${contact_id}`);
  }

  searchContacts(params: any) {
    return this.http.get(`${this.url}/integration-contacts`, { params });
  }

  updateContact(contact_id: any, payload: any) {
    return this.http.put(
      `${this.url}/integration-contacts/${contact_id}`,
      payload
    );
  }

  createContact(payload: any) {
    return this.http.post(`${this.url}/integration-contacts/`, payload);
  }

  deleteContact(contact_id: any) {
    return this.http.delete(`${this.url}/integration-contacts/${contact_id}`);
  }
}
