import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'app-json-viewer',
  standalone: true,
  imports: [],
  templateUrl: './json-viewer.component.html',
  styleUrl: './json-viewer.component.scss',
})
export class JsonViewerComponent {
  @Input() jsonData: any;
  @Input() height: number = 300;
  @Input() hideButton = false;
  @Input() isEditable = true;
  @Output() jsonValid = new EventEmitter<any>();
  @ViewChild('jsonEditor', { static: true }) jsonEditor!: ElementRef;
  notificationService = inject(NotificationService);

  ngAfterViewInit(): void {
    // this.jsonEditor.nativeElement.innerHTML = this.syntaxHighlight(''); // Initialize with an empty JSON
  }

  ngOnInit() {}

  clearJsonField() {
    this.jsonEditor.nativeElement.innerHTML = this.syntaxHighlight('');
  }

  updateEditorContent(message: string): void {
    this.jsonEditor.nativeElement.textContent = message;
  }

  syntaxHighlight(json: string): string {
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    const formatted = json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return `<span class="${cls}">${match}</span>`;
      }
    );
    return formatted;
  }

  getCaretCharacterOffsetWithin(element: HTMLElement): number {
    let caretOffset = 0;
    const doc = element.ownerDocument || document;
    const win = doc.defaultView || window;
    const sel = win.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  }

  setCaretPosition(element: HTMLElement, offset: number): void {
    const doc = element.ownerDocument || document;
    const win = doc.defaultView || window;
    const range = doc.createRange();
    const sel = win.getSelection();
    let currentOffset = 0;

    const setRange = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeLength = (node as Text).length;
        if (currentOffset + nodeLength >= offset) {
          range.setStart(node, offset - currentOffset);
          range.collapse(true);
          return true;
        } else {
          currentOffset += nodeLength;
        }
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          if (setRange(node.childNodes[i])) {
            return true;
          }
        }
      }
      return false;
    };

    setRange(element);
    sel?.removeAllRanges();
    sel?.addRange(range);
  }

  formatJSON(json: string): string | undefined {
    const parsed = JSON.parse(json);
    const formatted = JSON.stringify(parsed, null, 2);
    return this.syntaxHighlight(formatted);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { jsonData } = changes;

    if (!jsonData?.currentValue) {
      this.jsonEditor.nativeElement.innerHTML = this.syntaxHighlight('');
      return;
    }
    this.jsonEditor.nativeElement.textContent = JSON.stringify(
      jsonData.currentValue,
      null,
      2
    );
    this.validateJson();
  }

  formatAllJson(): void {
    const input = this.jsonEditor.nativeElement.textContent;
    if (!input.trim()) return;

    const sanitizedInput = input.replace(/\u00A0/g, ' ');
    const formattedJson = this.formatJSON(sanitizedInput);

    this.updateEditorContent(formattedJson || '');
  }

  validateJson(value?: any) {
    try {
      const input = value ?? this.jsonEditor.nativeElement.textContent;
      if (input.trim() === '') {
        this.jsonValid.emit(null);
        return;
      }

      const sanitizedInput = input.replace(/\u00A0/g, ' ');

      const parsed = JSON.parse(sanitizedInput);
      const formatted = JSON.stringify(parsed, null, 2);
      const syntaxJson = this.syntaxHighlight(formatted);
      this.jsonEditor.nativeElement.innerHTML = syntaxJson;

      const jsonString = this.jsonEditor.nativeElement.textContent;

      if (!jsonString) return;
      const updatedJson = JSON.parse(jsonString);
      this.jsonValid.emit(updatedJson);
    } catch (e) {
      console.log(e);
      this.notificationService.showErrorDialog(e as string);
      this.jsonValid.emit('jsonError');
    }
  }

  setPrintedObject(input: any) {
    const syntaxJson = this.syntaxHighlight(input);
    this.jsonEditor.nativeElement.innerHTML = syntaxJson;
  }

  setJsonEditorContent(content: string | object): void {
    this.jsonEditor.nativeElement.innerHTML = content;
    try {
      JSON.parse(content as string);
      this.validateJson();
    } catch (error) {
      console.log(error);
    }
  }
}
