import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRule(integration_id: number) {
    return this.http.get(`${this.url}/rules/${integration_id}`);
  }

  runRule(integration_id: number, payload?: any) {
    let formData = new FormData();

    Object.keys(payload).forEach((key) => {
      const value = payload[key];

      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    return this.http.post(`${this.url}/rules/${integration_id}/run`, formData);
  }

  updateTask(integration_id: number | null | undefined, payload: any) {
    return this.http.put(`${this.url}/rules/${integration_id}`, payload);
  }

  createTask(payload: any) {
    return this.http.post(`${this.url}/rules`, payload);
  }
}
