<div class="json-editor">
    <div #jsonEditor id="json-editor" [attr.contenteditable]="isEditable" class="editor resizable h-${{height}}"
        [style.height.px]="height">
    </div>
</div>
<div>
    @if (!hideButton) {
    <button id="format-json"
        class="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-1"
        (click)="validateJson()">Format JSON</button>
    }

</div>