import { Component, HostListener, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ContactService } from '../../core/services/contacts.service';
import { lastValueFrom } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';
import { FormsDataService } from '../../core/services/forms-data.service';
import { LoaderComponent } from '../../shared/components/loader/loader.component';

@Component({
  selector: 'app-contact-details',
  standalone: true,
  imports: [ReactiveFormsModule, MatIconModule, LoaderComponent],
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.scss',
})
export class ContactDetailsComponent {
  form: FormGroup;
  readonly contactService = inject(ContactService);
  readonly notificationService = inject(NotificationService);
  contactData = this.data?.contact;
  readonly customer_id = this.data?.customer_id;
  readonly action = this.data?.action;
  FormsDataService = inject(FormsDataService);
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ContactDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required]],
      company_position: ['', Validators.required],
      priority: [1, Validators.required],
      last_name: [''],
      phone: [''],
      mobile_phone: [''],
      skype: [''],
      notes: [''],
    });
  }

  ngOnInit(): void {
    if (this.contactData) this.initializeContact();
  }
  patchValues(contactData: any) {
    this.form.patchValue(contactData);
  }
  closeDialog() {
    this.notificationService.closeDialog(
      this.dialogRef,
      "Are you sure you want to close this view? Your changes won't be saved "
    );
  }
  onSubmit() {
    if (!this.form.valid) {
      this.FormsDataService.showFormErrors(this.form);
      return;
    }

    if (this.contactData) {
      this.updateContact();
    } else {
      this.createContact();
    }
  }
  @HostListener('document:keydown.escape', ['$event'])
  handleKeydown(): void {
    this.closeDialog();
  }
  async initializeContact() {
    try {
      this.loading = true;
      const contact$ = this.contactService.getContact(this.contactData.id);
      const { data } = (await lastValueFrom(contact$)) as any;
      this.contactData = data;
      this.patchValues(this.contactData);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async updateContact() {
    try {
      this.loading = true;
      const payloadUpdate = this.form.value;
      this.FormsDataService.replaceEmptyStringsWithNull(payloadUpdate);
      const updateContact$ = this.contactService.updateContact(
        this.contactData.id,
        {
          ...this.contactData,
          ...payloadUpdate,
        }
      );
      const { data } = (await lastValueFrom(updateContact$)) as any;
      this.notificationService.showSuccessNotification();
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.loading = false;
    }
  }

  async createContact() {
    try {
      this.loading = true;
      const payloadCreate = this.form.value;
      this.FormsDataService.replaceEmptyStringsWithNull(payloadCreate);
      const createContact$ = this.contactService.createContact({
        id_hotel: this.customer_id,
        deleted: false,
        ...this.form.value,
      });
      const { data } = (await lastValueFrom(createContact$)) as any;
      this.notificationService.showSuccessNotification();
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.loading = false;
    }
  }
}
