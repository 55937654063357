import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  effect,
  inject,
} from '@angular/core';
import { NotificationService } from '../../core/services/notification.service';
import { MappersService } from '../../core/services/mappers.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { generalParams } from '../../core/models/Params';
import { forkJoin, lastValueFrom, throwError } from 'rxjs';
import { IMapper } from '../../core/models/IMapper';
import { MatDividerModule } from '@angular/material/divider';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';
import { DiscartableDataTab } from '../../core/models/DiscartableDataTab';
import { TabsService } from '../../core/services/tabs.service';
import { FormsDataService } from '../../core/services/forms-data.service';
import { JsonViewerComponent } from '../../shared/components/json-viewer/json-viewer.component';

@Component({
  selector: 'app-mappers',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    JsonViewerComponent,
  ],
  templateUrl: './mappers.component.html',
  styleUrl: './mappers.component.scss',
})
export class MappersComponent implements DiscartableDataTab {
  notificationService = inject(NotificationService);
  mappersService = inject(MappersService);
  integrationProfileDataService = inject(IntegrationProfileDataService);
  tabsService = inject(TabsService);
  FormsDataService = inject(FormsDataService);
  mapperForm: FormGroup;
  regexForm: FormGroup;
  fileForm: FormGroup;
  mapper: IMapper | null = null;
  @Input() integrator_id: any;
  resultFile: any;
  resultRegex: any;
  operations: string[] = [];
  separators: string[] = [];
  jsonOutput: string = '';
  jsonData: any;
  invalidJson: boolean = false;
  @ViewChild('jsonViewer') jsonViewer!: JsonViewerComponent;

  constructor(private fb: FormBuilder) {
    effect(() => {
      this.integrator_id =
        this.integrationProfileDataService.selectedIntegration()?.id ??
        this.integrator_id;
    });

    this.mapperForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      integration_id: [null],
      deleted: [false],
      mapper_fields: ['', Validators.required],
    });

    this.regexForm = this.fb.group({
      operation: ['', Validators.required],
      target: ['', Validators.required],
      replacement: [''],
      value: ['', Validators.required],
    });

    this.fileForm = this.fb.group({
      integration_id: [''],
      file: [null, Validators.required],
      fields: [''],
    });
  }
  hasFormChanged(): boolean {
    this.updateMapperFields();
    return this.tabsService.hasOriginalValueChanged(
      {
        mapper_fields: this.mapperForm.get('mapper_fields')?.value || null,
        name: this.mapperForm.get('name')?.value || null,
      },
      this.mapper
        ? {
            mapper_fields: this.mapper?.mapper_fields
              ? JSON.stringify(this.mapper.mapper_fields, null, 2)
              : null,
            name: this.mapper.name || null,
          }
        : {
            mapper_fields: null,
            name: null,
          }
    );
  }

  ngOnInit(): void {
    if (this.integrator_id) this.initializeMapper();
    this.initializeSelectors();
  }

  ngOnDestroy(): void {
    this.tabsService.clearUnsavedChanges();
  }

  private async initializeMapper() {
    const getMapper$ = this.mappersService.getIntegrationMappers({
      ...generalParams,
      size: 3000,
      integration_id: this.integrator_id,
      deleted: false,
    });
    const { data } = await lastValueFrom(getMapper$);

    this.mapper = data[0];
    this.jsonData = this.mapper?.mapper_fields;

    this.patchValues(this.mapper);
  }

  private async initializeSelectors() {
    try {
      const getMapper$ = this.mappersService.getMapperOperations();
      const geSeparators$ = this.mappersService.getMapperSeparators();
      this.notificationService.startLoading();

      const observers = {
        next: (results: any) => {
          this.operations = results.getMapper$.data;
          this.separators = results.geSeparators$.data;
          this.notificationService.stopLoading();
        },
        error: (error: any) => {
          console.error('Error fetching data', error);
        },
      };

      forkJoin({
        getMapper$: getMapper$,
        geSeparators$: geSeparators$,
      }).subscribe(observers);
    } catch (error) {
      this.notificationService.stopLoading();
    }
  }

  private patchValues(mapper: IMapper | null) {
    if (!mapper) return;
    if (mapper?.mapper_fields) this.jsonData = [...mapper.mapper_fields];
    else this.jsonViewer.clearJsonField();
    this.mapperForm.patchValue({
      ...mapper,
      mapper_fields: mapper?.mapper_fields
        ? JSON.stringify(mapper.mapper_fields, null, 2)
        : null,
    });
  }

  updateMapperFields() {
    this.jsonViewer.validateJson();
  }

  onJsonValid(updatedJson: any) {
    if (updatedJson === 'jsonError') {
      this.invalidJson = true;
    } else {
      this.invalidJson = false;
    }
    if (updatedJson)
      this.mapperForm
        .get('mapper_fields')
        ?.setValue(JSON.stringify(updatedJson, null, 2));
  }

  saveMapper() {
    this.updateMapperFields();

    if (!this.mapperForm.valid) {
      this.FormsDataService.showFormErrors(this.mapperForm);
      return;
    }

    if (this.invalidJson) return;

    if (this.mapper) {
      this.updateMapper();
    } else {
      this.createMapper();
    }
  }
  async createMapper() {
    try {
      this.notificationService.startLoading();
      const createMapper$ = this.mappersService.createMapper({
        ...this.mapperForm.value,
        ...this.mapper,
        integration_id: this.integrator_id,
        mapper_fields: JSON.parse(this.mapperForm.get('mapper_fields')?.value),
      });
      const { data } = (await lastValueFrom(createMapper$)) as any;
      this.mapper = data;
      await this.initializeMapper();
      this.notificationService.showSuccessNotification();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }

  async updateMapper() {
    if (!this.mapper) return;
    try {
      this.notificationService.startLoading();
      const updateMapper$ = this.mappersService.updateMultiMapper(
        this.mapper.id,
        {
          ...this.mapper,
          ...this.mapperForm.value,
          mapper_fields: JSON.parse(
            this.mapperForm.get('mapper_fields')?.value
          ),
        }
      );
      const { data } = (await lastValueFrom(updateMapper$)) as any;
      this.mapper = data;
      await this.initializeMapper();
      this.notificationService.showSuccessNotification();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorDialog(error as string);
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }

  discardUnsavedChanges() {
    if (this.mapper) {
      this.jsonData = [...this.mapper?.mapper_fields];
      this.patchValues(this.mapper);
    } else {
      this.mapperForm.reset();
      this.jsonViewer.clearJsonField();
    }
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    this.fileForm.patchValue({ file });
    this.fileForm.get('file')?.updateValueAndValidity();
  }

  closeDialog() {
    this.notificationService.closeTabsDialog();
  }
  async onSubmitFile() {
    this.updateMapperFields();
    if (this.invalidJson) return;
    if (!this.fileForm.valid) {
      this.FormsDataService.showFormErrors(this.fileForm);
      return;
    }
    if (
      !this.integrationProfileDataService.selectedIntegration()
        ?.mapper_separator
    ) {
      this.notificationService.showErrorDialog(
        'Integration profile must have a File Separator'
      );
      return;
    }

    let payloadFile;
    try {
      if (!this.mapperForm.get('mapper_fields')?.value) {
        throw new Error('Fields is required');
      }
      payloadFile = {
        ...this.fileForm.value,
        integration_id: this.mapper?.integration_id || this.integrator_id,
        fields: JSON.parse(this.mapperForm.get('mapper_fields')?.value),
      };
    } catch (error) {
      this.notificationService.showErrorDialog(error as string);
      return;
    }

    try {
      this.notificationService.startLoading();
      const testFile$ = this.mappersService.testMapperFields(payloadFile);
      const { data } = (await lastValueFrom(testFile$)) as any;
      this.resultFile = JSON.stringify(data, null, 2);
      this.notificationService.showResultDialog(this.resultFile);
    } catch (error) {
      console.log(error);
    } finally {
      this.notificationService.stopLoading();
    }
  }
  async onSubmitRegex() {
    if (!this.regexForm.valid) {
      this.FormsDataService.showFormErrors(this.regexForm);
      return;
    }

    try {
      this.notificationService.startLoading();
      const testRegex$ = this.mappersService.testRegex(this.regexForm.value);
      const { data } = (await lastValueFrom(testRegex$)) as any;
      this.resultRegex = data;
      this.notificationService.showResultDialog(this.resultRegex);
    } catch (error) {
      console.log(error);
    } finally {
      this.notificationService.stopLoading();
    }
  }
}
