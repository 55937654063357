import { Injectable, inject, signal } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../shared/components/message-dialog/message-dialog.component';
import { IntegrationProfileDataService } from './Integration-profile-data.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { lastValueFrom } from 'rxjs';
import { TabsService } from './tabs.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { InputDialogConfirmationComponent } from '../../shared/components/input-dialog-confirmation/input-dialog-confirmation.component';

@Injectable({
  providedIn: 'root',
})
// REF: Service to manage modal and notifications
export class NotificationService {
  integrationProfileDataService = inject(IntegrationProfileDataService);
  tabsService = inject(TabsService);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  readonly dialogNotificationsConf = {
    width: '43%',
    minHeight: '45%',
  };
  readonly snackNotificationsConfig = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    duration: 3000,
  };
  readonly dialogInputDialog = {
    width: '400px',
    minHeight: '200px',
  };
  isTabDialogOpen = signal(true);
  isLoading = signal(false);

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {}

  startLoading() {
    this.isLoading.set(true);
  }

  stopLoading() {
    this.isLoading.set(false);
  }

  openTabsDialog() {
    this.isTabDialogOpen.set(true);
  }

  async closeTabsDialog() {
    if (!this.tabsService.hasUnsavedChanges) {
      this.closeIntegrationDetails();
      return;
    }

    const resp$ = this._dialog
      .open(ConfirmationDialogComponent, {
        data: 'There are changes without save, are you sure you want to discard them?',
      })
      .afterClosed();
    const resp = await lastValueFrom(resp$);

    if (!resp) return;
    this.closeIntegrationDetails();
  }

  closeIntegrationDetails() {
    this.isTabDialogOpen.set(false);
    this.integrationProfileDataService.clearSelectedIntegration();
    this._dialog.closeAll();
  }

  showErrorDialog(message: string) {
    this._dialog.open(MessageDialogComponent, {
      ...this.dialogNotificationsConf,
      data: {
        message,
        title: 'Error',
      },
    });
  }

  showResultDialog(message: string) {
    this._dialog.open(MessageDialogComponent, {
      ...this.dialogNotificationsConf,
      data: {
        message,
        title: 'Result',
      },
    });
  }

  async showInputDialog() {
    const resp$ = this._dialog
      .open(InputDialogConfirmationComponent, {
        ...this.dialogInputDialog,
      })
      .afterClosed();

    const resp = await lastValueFrom(resp$);

    return resp;
  }

  async closeDialog(dialogRef: MatDialogRef<any>, message: string) {
    const resp$ = this._dialog
      .open(ConfirmationDialogComponent, {
        data: message,
      })
      .afterClosed();
    const resp = await lastValueFrom(resp$);

    if (!resp) return;

    dialogRef.close();
  }

  showErrorNotification() {
    this._snackBar.open('Error', 'X', {
      ...this.snackNotificationsConfig,
      panelClass: ['error-snackbar'],
    });
  }

  showSuccessNotification() {
    this._snackBar.open('Success', 'X', {
      ...this.snackNotificationsConfig,
      panelClass: ['success-snackbar'],
    });
  }
}
