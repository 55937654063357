<div class="w-full bg-primary h-10 flex items-center justify-between">
  <label class="text-white pl-5 text-lg">Integration Manager {{ ActiveEnvironment }} </label>
  <mat-icon (click)="logout()" class="text-white cursor-pointer mr-5">exit_to_app</mat-icon>
</div>

@if (loading) {
<app-loader></app-loader>
}

<div class="flex w-full">
  <div class="w-full bg-white border border-gray-200 px-5 overflow-auto">
    <div class="flex flex-col md:flex-row justify-between items-center">
      <form (submit)="applyFilter(input, $event)"
        class="md:w-1/3 max-w-full w-full md:max-w-[32rem] mt-5 md:mt-0 md:py-5">
        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
        <div class="relative">
          <input #input placeholder="ID | Customer ID | Customer Name | PMS" type="search" id="default-search"
            class="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" />
          <button type="button" (click)="applyFilter(input)" id="search-button"
            class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white rounded-e-lg border border-primary focus:ring-4 focus:outline-none focus:ring-blue-300 bg-primary">
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round-full" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
            <span class="sr-only">Search</span>
          </button>
        </div>
      </form>

      <button type="button" (click)="openIntegrationDetails()" id="create-button"
        class="mt-4 md:mt-0 md:ml-4 p-3 w-12 h-12 text-sm font-medium text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full flex items-center justify-center">
        <mat-icon>add</mat-icon>
      </button>
    </div>



    <div class="overflow-y-auto">
      <table mat-table [dataSource]="dataSource" class="limited-table" id="integrations-table"
        class="mat-elevation-z8 mt-4 min-w-100 w-full max-w-4xl mx-auto overflow-auto custom-table">
        <ng-container matColumnDef="actions">
          <th class="!w-20 !min-w-20" mat-header-cell *matHeaderCellDef></th>
          <td class="!w-20 !min-w-20" mat-cell *matCellDef="let element">
            <div class="flex">
              <button id="run-button" class="mx-1 action-button" mat-icon-button (click)="run(element)">
                <mat-icon class="text-green-500">play_circle_filled</mat-icon>
              </button>
              <button id="edit-button" class="mx-1 action-button" mat-icon-button (click)="edit(element)">
                <mat-icon class="text-primary">edit</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th class="!w-20 !min-w-20" mat-header-cell *matHeaderCellDef> ID </th>
          <td class="!w-20 !min-w-20" mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="customer.id">
          <th class="!w-24 !min-w-24" mat-header-cell *matHeaderCellDef> Customer ID </th>
          <td class="!w-24 !min-w-24" mat-cell *matCellDef="let element"> {{element.customer?.id}} </td>
        </ng-container>
        <ng-container matColumnDef="customer.name">
          <th class="width-250" mat-header-cell *matHeaderCellDef> Customer Name </th>
          <td class="width-250" mat-cell *matCellDef="let element"> {{element.customer?.name}} </td>
        </ng-container>
        <ng-container matColumnDef="integration_type">
          <th mat-header-cell *matHeaderCellDef> Int. Type (Old) </th>
          <td mat-cell *matCellDef="let element"> {{element?.integration_type}} </td>
        </ng-container>
        <ng-container matColumnDef="integration_pms.name">
          <th mat-header-cell *matHeaderCellDef> PMS </th>
          <td mat-cell *matCellDef="let element"> {{element.integration_pms?.name}} </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef> URL </th>
          <td mat-cell *matCellDef="let element"> {{element.url}} </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> User </th>
          <td mat-cell *matCellDef="let element"> {{element.user}} </td>
        </ng-container>
        <ng-container matColumnDef="password">
          <th mat-header-cell *matHeaderCellDef> Password </th>
          <td mat-cell *matCellDef="let element"> {{element.password}} </td>
        </ng-container>
        <ng-container matColumnDef="ftp_path">
          <th mat-header-cell *matHeaderCellDef> FTP Path </th>
          <td mat-cell *matCellDef="let element"> {{element.ftp_path}} </td>
        </ng-container>
        <ng-container matColumnDef="mapper_separator">
          <th mat-header-cell *matHeaderCellDef> Mapper Separator </th>
          <td mat-cell *matCellDef="let element"> {{element.mapper_separator}} </td>
        </ng-container>
        <ng-container matColumnDef="pms">
          <th mat-header-cell *matHeaderCellDef> Int. PMS (Old) </th>
          <td mat-cell *matCellDef="let element"> {{element.pms}} </td>
        </ng-container>
        <ng-container matColumnDef="provider_id">
          <th mat-header-cell *matHeaderCellDef> Provider ID </th>
          <td mat-cell *matCellDef="let element"> {{element.provider_id}} </td>
        </ng-container>
        <ng-container matColumnDef="forced_integration_quality">
          <th mat-header-cell *matHeaderCellDef> Forced Integration Quality </th>
          <td mat-cell *matCellDef="let element"> {{element.forced_integration_quality}} </td>
        </ng-container>
        <ng-container matColumnDef="mapper_file_extension">
          <th mat-header-cell *matHeaderCellDef> Mapper File Extension </th>
          <td mat-cell *matCellDef="let element"> {{element.mapper_file_extension}} </td>
        </ng-container>
        <ng-container matColumnDef="obtain_data">
          <th mat-header-cell *matHeaderCellDef> Obtain Data </th>
          <td mat-cell *matCellDef="let element"> {{element.obtain_data | json}} </td>
        </ng-container>
        <ng-container matColumnDef="disable_filter_notification">
          <th mat-header-cell *matHeaderCellDef> Disable Filter Notification </th>
          <td mat-cell *matCellDef="let element"> {{element.disable_filter_notification}} </td>
        </ng-container>

        <ng-container matColumnDef="type.name">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let element"> {{element.type?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="type.id">
          <th mat-header-cell *matHeaderCellDef> Type ID </th>
          <td mat-cell *matCellDef="let element"> {{element.type?.id}} </td>
        </ng-container>

        <ng-container matColumnDef="flag_filter_domains">
          <th mat-header-cell *matHeaderCellDef> Flag Filter Domains </th>
          <td mat-cell *matCellDef="let element"> {{element.flag_filter_domains}} </td>
        </ng-container>
        <ng-container matColumnDef="survey_types_default">
          <th class="!w-32 !min-w-32" mat-header-cell *matHeaderCellDef> Surveys Default </th>
          <td class="!w-32 !min-w-32" mat-cell *matCellDef="let element"> {{element.survey_types_default}} </td>
        </ng-container>
        <ng-container matColumnDef="flag_alerts_active">
          <th class="!w-24 !min-w-24" mat-header-cell *matHeaderCellDef> Flag Alerts </th>
          <td class="!w-24 !min-w-24" mat-cell *matCellDef="let element"> {{element.flag_alerts_active}} </td>
        </ng-container>
        <ng-container matColumnDef="alert_days">
          <th class="!w-24 !min-w-24" mat-header-cell *matHeaderCellDef> Alert Days </th>
          <td class="!w-24 !min-w-24" mat-cell *matCellDef="let element"> {{element.alert_days}} </td>
        </ng-container>
        <ng-container matColumnDef="seasonal_flag">
          <th class="!w-28 !min-w-28" mat-header-cell *matHeaderCellDef> Seasonal Flag </th>
          <td class="!w-28 !min-w-28" mat-cell *matCellDef="let element"> {{element.seasonal_flag}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="open_date">
          <th mat-header-cell *matHeaderCellDef> Open Date </th>
          <td mat-cell *matCellDef="let element"> {{element.open_date}} </td>
        </ng-container>
      
        <ng-container matColumnDef="close_date">
          <th mat-header-cell *matHeaderCellDef> Close Date </th>
          <td mat-cell *matCellDef="let element"> {{element.close_date}} </td>
        </ng-container> -->
        <ng-container matColumnDef="connection_type">
          <th mat-header-cell *matHeaderCellDef> Connection Type </th>
          <td mat-cell *matCellDef="let element"> {{element.connection_type}} </td>
        </ng-container>
        <ng-container matColumnDef="excluded_domains">
          <th mat-header-cell *matHeaderCellDef> Excluded Domains </th>
          <td mat-cell *matCellDef="let element"> {{element.excluded_domains}} </td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef> Notes </th>
          <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="edit(row, $event)"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    </div>

    <mat-paginator #MatPaginator (page)="onPageChange($event)" [length]="totalOfElements" [pageSize]="params.size"
      [pageIndex]="params.page" [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" class="my-4">
    </mat-paginator>

  </div>
</div>