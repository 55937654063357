import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCustomer(customer_id: any) {
    return this.http.get(`${this.url}/integration-contacts/${customer_id}`);
  }
}
