import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Md5 } from 'ts-md5';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private securityUrl = environment.security_url;
  private apiKey = environment.apiKey;
  private tokenKey = 'token';
  private refreshTokenKey = 'refreshToken';
  private localStoragevalue: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.localStoragevalue = this.document.defaultView?.localStorage;
  }

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    password = Md5.hashStr(password);
    const body = { username, password };

    return this.http
      .post<{ token: string; refreshToken: string }>(
        `${this.securityUrl}/login/internal`,
        body,
        { headers }
      )
      .pipe(
        tap((response) => {
          this.localStoragevalue.setItem(this.tokenKey, response.token);
          this.localStoragevalue.setItem(
            this.refreshTokenKey,
            response.refreshToken
          );
        })
      );
  }

  logout(): void {
    this.dialog.closeAll();
    this.localStoragevalue.removeItem(this.tokenKey);
    this.localStoragevalue.removeItem(this.refreshTokenKey);
    this.router.navigate(['/login']);
  }

  get token(): string | null {
    return this.localStoragevalue?.getItem(this.tokenKey) || null;
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.localStoragevalue.getItem(this.refreshTokenKey);
    if (!refreshToken) {
      this.logout();
      return of(null);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{ token: string }>(
        `${this.securityUrl}/refresh-token`,
        { refreshToken },
        { headers }
      )
      .pipe(
        tap((response) => {
          this.localStoragevalue.setItem(this.tokenKey, response.token);
        })
      );
  }

  get apiKeyHeader(): string {
    return this.apiKey;
  }

  get isLoggedIn(): boolean {
    return !!this.token;
  }
}
