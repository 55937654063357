import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  IResponseIntegrationPms,
  IResponseIntegrationType,
  IResponseString,
} from '../models/IResponse';
import { Observable } from 'rxjs';
import { IIntegration } from '../models/IIntegration';

@Injectable({
  providedIn: 'root',
})
export class IntegrationProfileService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getIntegrationProfiles(params: any) {
    return this.http.get(`${this.url}/integration-profiles`, { params });
  }

  getIntegrationProfile(integration_id: number | null) {
    return this.http.get(`${this.url}/integration-profiles/${integration_id}`);
  }

  getIntegrationTypes(): Observable<IResponseIntegrationType> {
    return this.http.get<IResponseIntegrationType>(
      `${this.url}/integration-profiles/integration-types`
    );
  }

  getIntegrationPmss(): Observable<IResponseIntegrationPms> {
    return this.http.get<IResponseIntegrationType>(
      `${this.url}/integration-profiles/integration-pmss`
    );
  }

  getIntegrationQualities(): Observable<IResponseString> {
    return this.http.get<IResponseString>(
      `${this.url}/integration-profiles/integration-qualities`
    );
  }

  getFileCharsets() {
    return this.http.get<IResponseString>(
      `${this.url}/integration-profiles/file-charsets`
    );
  }

  updateIntegrationProfile(
    integrationId: number | undefined,
    payload: IIntegration | undefined | null
  ) {
    return this.http.put(
      `${this.url}/integration-profiles/${integrationId}`,
      payload
    );
  }

  createIntegrationProfile(payload: IIntegration | undefined) {
    return this.http.post(`${this.url}/integration-profiles/`, payload);
  }
}
