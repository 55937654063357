import { Injectable, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class FormsDataService {
  notificationService = inject(NotificationService);

  showFormErrors(input: FormGroup | string) {
    if (typeof input === 'string') {
      this.notificationService.showErrorDialog(input);
      return;
    }

    let strError = '';
    Object.keys(input.controls).forEach((key) => {
      const controlErrors = input.get(key)?.errors;
      if (controlErrors != null) {
        strError +=
          JSON.stringify(key + ':') + JSON.stringify(controlErrors) + '\n';
      }
    });
    this.notificationService.showErrorDialog(strError);
  }

  replaceEmptyStringsWithNull(obj: any, excludeProps: string[] = []): any {
    if (obj !== null && typeof obj === 'object') {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          obj[i] = this.replaceEmptyStringsWithNull(obj[i], excludeProps);
        }
      } else {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (obj[key] === '' && !excludeProps.includes(key)) {
              obj[key] = null;
            } else if (typeof obj[key] === 'object') {
              this.replaceEmptyStringsWithNull(obj[key], excludeProps);
            }
          }
        }
      }
    }
    return obj;
  }
}
