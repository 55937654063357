import { Component, Input, effect, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationService } from '../../core/services/notification.service';
import { UploaderService } from '../../core/services/uploader.service';
import { generalParams } from '../../core/models/Params';
import { lastValueFrom } from 'rxjs';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-uploader',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './uploader.component.html',
  styleUrl: './uploader.component.scss',
})
export class UploaderComponent {
  configuration_id: string = '';
  @Input() integrator_id: number | null | undefined = null;
  notificationService = inject(NotificationService);
  uploaderService = inject(UploaderService);
  integrationProfileDataService = inject(IntegrationProfileDataService);
  uploader: any;
  isButtonDisabled: boolean = false;
  constructor() {
    effect(() => {
      this.integrator_id =
        this.integrationProfileDataService.selectedIntegration()?.id ??
        this.integrator_id;
    });
  }
  ngOnInit(): void {
    this.initializeUploader();
  }

  async initializeUploader() {
    if (!this.integrator_id) return;

    try {
      this.notificationService.startLoading();
      const uploader$ = this.uploaderService.getUploader({
        ...generalParams,
        integration_id: this.integrator_id,
      });
      const { data } = (await lastValueFrom(uploader$)) as any;
      this.uploader = data[0];
      this.configuration_id = this.uploader.id;
      if (this.configuration_id) this.isButtonDisabled = true;
    } catch (error) {
    } finally {
      this.notificationService.stopLoading();
    }
  }

  async save() {
    try {
      this.notificationService.startLoading();
      const uploader$ = this.uploaderService.createUploader({
        integration_id: this.integrator_id,
      });
      const { data } = (await lastValueFrom(uploader$)) as any;
      this.uploader = data;
      this.configuration_id = this.uploader.id;
      if (this.configuration_id) this.isButtonDisabled = true;
      this.notificationService.showSuccessNotification();
    } catch (error) {
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }
  closeDialog() {
    this.notificationService.closeTabsDialog();
  }
}
