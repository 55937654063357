import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { JsonViewerComponent } from '../json-viewer/json-viewer.component';

@Component({
  selector: 'app-error-message-dialog',
  standalone: true,
  imports: [MatIconModule, JsonViewerComponent],
  templateUrl: './message-dialog.component.html',
  styleUrl: './message-dialog.component.scss',
})
export class MessageDialogComponent {
  readonly message;
  readonly title;
  @ViewChild('jsonViewer', { static: true }) jsonViewer!: JsonViewerComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MessageDialogComponent>
  ) {
    this.message = data.message;
    this.title = data.title;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.jsonViewer.setJsonEditorContent(this.message as string);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
