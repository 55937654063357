<div class="flex w-full max-w-4xl mx-auto mt-3 custom-dialog-content">
  <div class="w-full bg-white border border-gray-200 px-3 overflow-auto">

    <div class="w-full flex justify-end pt-2">
      <button type="button" (click)="openDetails()" id="contact-create-btn"
        class="mt-4 md:mt-0 md:ml-4 p-3 w-12 h-12 text-sm font-medium text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full flex items-center justify-center">
        <mat-icon>add</mat-icon>
      </button>
    </div>



    <div class="overflow-y-auto">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full my-4" id="contacts-table">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="min-w-3.5">
            <div class="flex">
              <button class="mx-1 action-button" mat-icon-button (click)="edit(element)">
                <mat-icon class="text-primary">edit</mat-icon>
              </button>
              <button class="mx-1 action-button" mat-icon-button (click)="deleteElement(element)"
                id="contact-delete-btn">
                <mat-icon class="text-red-500">delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef> Prioridad </th>
          <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
        </ng-container>
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef> First name </th>
          <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
        </ng-container>
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef> Last name </th>
          <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
        <ng-container matColumnDef="company_position">
          <th mat-header-cell *matHeaderCellDef> Company position </th>
          <td mat-cell *matCellDef="let element"> {{element.company_position}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="
        edit(row,$event)"></tr>
      </table>
    </div>

  </div>
</div>