<div class="w-full flex flex-wrap p-3 max-w-4xl mx-auto mt-3">
    <div class="flex flex-wrap w-full">

        <div class="w-full md:w-3/4 mb-4 md:mb-0">
            <label for="customerName" class="block mb-2 text-sm font-medium text-gray-900">Configuration ID</label>
            <input readonly [(ngModel)]="configuration_id" type="text" id="customerName"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Configuration ID" required />
        </div>


        <div class="w-full md:w-1/4 flex items-center justify-center md:justify-end mt-4 md:mt-auto">
            <button [ngClass]="{'!bg-gray-400':isButtonDisabled}" type="button" (click)="save()"
                id="uploader-create-btn" [disabled]="isButtonDisabled"
                class="text-white bg-green-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Create</button>
        </div>
    </div>

    <div class="flex flex-col sm:flex-row justify-between w-full pt-5">
        <div class="flex flex-col sm:flex-row sm:ml-auto">
        </div>
    </div>
</div>