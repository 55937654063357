<div class="overflow-auto h-full">
    <div class="dialog-header w-full sticky top-0 z-10">
        <div class="w-full bg-primary h-9 flex items-center justify-between">
            <label class="text-white pl-2 text-lg">Integration Manager</label>
            <mat-icon (click)="closeDialog()" class="text-white cursor-pointer mr-2">close</mat-icon>
        </div>

        <div class="w-full mr-auto bg-gray-50 rounded-lg shadow-lg mb-1">
            <div class="p-3 bg-gray-200 rounded-t-lg flex items-center">
                <h2 class="text-gray-900 text-2xl font-bold mb-0">
                    Integration: <span data-testid="integration-id">{{ this.integration?.id }}</span> |
                    Customer: {{ this.integration?.customer?.id }} - {{ this.integration?.customer?.name }}
                </h2>
                <a href="https://docs.google.com/document/d/1OnbJ9xyp3VGqeoNSIo_eb7p5UGk5RR4DN0XYZmDoZT0/edit#heading=h.2oak6qfoxkjk"
                    class="text-end pl-5 ml-auto text-blue-500 hover:underline" target="_blank"
                    rel="noopener noreferrer">Configuración nuevas integraciones</a>
            </div>
        </div>
    </div>

    @if (loading) {
    <app-loader></app-loader>
    }

    <div>
        <mat-tab-group #tabGroup [color]="" class="bg-gray-50 rounded-lg tab-custom-overflow" animationDuration="200ms"
            mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Integration" id="Integration">
                <app-integration #Integration [integratorData]="integration"></app-integration>
            </mat-tab>
            <mat-tab label="Filters" [disabled]="disabledTabs" id="Filters">
                <app-filters [integrator_id]="integration?.id"></app-filters>
            </mat-tab>
            <mat-tab label="Mappers" [disabled]="disabledTabs" id="Mappers">
                <app-mappers #Mappers [integrator_id]="integration?.id"></app-mappers>
            </mat-tab>
            <mat-tab label="Task" [disabled]="disabledTabs" id="Task">
                <app-tasks #Task [integrator_id]="integration?.id"></app-tasks>
            </mat-tab>
            <mat-tab label="Uploader" [disabled]="disabledTabs" id="Uploader">
                <app-uploader [integrator_id]="integration?.id"></app-uploader>
            </mat-tab>
            <mat-tab label="Contacts" [disabled]="disabledTabs" id="Contacts">
                <app-contacts [customer_id]="integration?.customer?.id"></app-contacts>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>