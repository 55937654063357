@if (loading) {
<app-loader></app-loader>
}

<div class="w-full bg-primary h-9 flex items-center justify-between">
    <label class="text-white pl-2 text-lg">Integration Manager</label>
    <mat-icon (click)="closeDialog()" class="text-white cursor-pointer mr-2">close</mat-icon>
</div>

<div [formGroup]="form" class="p-3 bg-white rounded-lg shadow-md w-full max-w-4xl mx-auto mt-3">
    <label class="block mb-2 text-2xl font-medium text-gray-900">Filter {{action}}</label>
    <div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Name</label>
            <input formControlName="name" id="filter-name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Name" required />
        </div>
    </div>

    <div class="flex items-center mt-3">
        <div class="w-full md:w-3/4 mb-4 md:mb-0">
            <label class="block mb-2 text-sm font-medium text-gray-900">Surveys</label>
            <input type="text" id="survey_types" formControlName="survey_types" id="filter-surveys"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="surveys" required />
        </div>
        <div class="w-full md:w-1/4 flex flex-col items-center justify-center md:justify-start ml-4">
            <label class="block mb-2 text-sm font-medium text-gray-900">Exclude</label>
            <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" formControlName="exclude" id="filter-exclude">
                <div
                    class="relative w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-current">
                </div>
            </label>
        </div>
    </div>

    <div class="mt-3">
        <div class="w-full min-h-72 flex flex-col justify-between h-full">
            <div class="flex-grow">
                <div class="flex">
                    <label class="block mb-2 text-sm font-medium text-gray-900">Filters</label>
                    <a href="https://docs.google.com/document/d/1KSjelFG2sR-WMFAVKlm1-bWHBr0msJNDVcqMJM00HlE/edit"
                        class="pl-5 ml-auto text-blue-500 hover:underline" target="_blank"
                        rel="noopener noreferrer">Ejemplos
                        de filtros mas utilizados</a>
                </div>
                <app-json-viewer #jsonViewer [jsonData]="jsonData" (jsonValid)="onJsonValid($event)"></app-json-viewer>
            </div>
            <div class="mt-3 p-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                role="alert">
                <span class="font-medium">New: </span> No enviar un filtro con el campo 'id' porque en lugar de crear
                uno nuevo va a actualizar un filtro existente <br>
                <span class="font-medium">Edit: </span> Si se quiere editar un grupo de filtros enviar el campo 'id' en
                cada filtro, en caso de que no se especifique, se creara un nuevo filtro asociado al Filter Group
                actual.
            </div>
            <div class="w-full flex justify-end mt-4">
                <button (click)="save()" type="submit" id="filter-save-btn"
                    class="my-5 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
            </div>
        </div>
    </div>
</div>