import { Component, Input, inject, Inject, effect } from '@angular/core';
import { TasksService } from '../../core/services/tasks.service';
import { lastValueFrom } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NotificationService } from '../../core/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';
import { DiscartableDataTab } from '../../core/models/DiscartableDataTab';
import { TabsService } from '../../core/services/tabs.service';
import { FormsDataService } from '../../core/services/forms-data.service';
import { CommonModule } from '@angular/common';
import { generalParams } from '../../core/models/Params';
import { FilterGroupService } from '../../core/services/filter-group.service';

@Component({
  selector: 'app-tasks',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './tasks.component.html',
  styleUrl: './tasks.component.scss',
})
export class TasksComponent implements DiscartableDataTab {
  @Input() integrator_id: number | null | undefined = null;
  readonly tasksService = inject(TasksService);
  readonly notificationService = inject(NotificationService);
  readonly integrationProfileDataService = inject(
    IntegrationProfileDataService
  );
  readonly tabsService = inject(TabsService);
  readonly FormsDataService = inject(FormsDataService);
  readonly filterGroupService = inject(FilterGroupService);
  frecuency_hours: string = '';
  enabled: boolean = false;
  task: any;
  name: string = '';
  description: string = '';

  constructor(private dialog: MatDialog) {
    effect(() => {
      this.integrator_id =
        this.integrationProfileDataService.selectedIntegration()?.id ??
        this.integrator_id;
    });
  }
  ngOnInit(): void {
    this.initializeTask();
  }
  async initializeTask() {
    if (this.integrator_id) {
      const taskResponse$ = this.tasksService.getRule(this.integrator_id);
      const { data } = ((await lastValueFrom(taskResponse$)) as any) ?? {};
      this.patchValues(data);
    }
  }

  hasFormChanged() {
    this.tabsService.hasOriginalValueChanged(
      {
        enabled: this.enabled || false,
        frecuency_hours: +this.frecuency_hours || 0,
      },
      {
        enabled: this.task?.enabled || false,
        frecuency_hours: +this.task?.frecuency_hours || 0,
      }
    );
  }

  private patchValues(data: any) {
    this.task = data;
    this.frecuency_hours = data?.frecuency_hours;
    this.enabled = data?.enabled ?? false;
    this.description = data?.description ?? '';
    this.name = data?.name ?? '';
  }

  private async verifyFilters(params: {
    page: number;
    size: number;
    sort_dir: string;
    sort: string;
    deleted: boolean;
    integration_id?: number;
  }) {
    this.notificationService.startLoading();
    const filterGroups$ = this.filterGroupService.searchProfileGroup(params);
    const { data } = (await lastValueFrom(filterGroups$)) as any;
    const existFilters = !!data.length;
    this.notificationService.stopLoading();
    return existFilters;
  }

  async run() {
    if (!this.integrator_id) return;

    const existFilters = await this.verifyFilters({
      ...generalParams,
      integration_id: this.integrator_id,
      deleted: false,
      size: 3000,
    });

    if (!existFilters) {
      const resp$ = this.dialog
        .open(ConfirmationDialogComponent, {
          data: 'There are not filters, are you sure run this task?',
        })
        .afterClosed();
      const resp = await lastValueFrom(resp$);

      if (!resp) return;
    }

    const resp = await this.notificationService.showInputDialog();

    if (!resp) return;

    this.notificationService.startLoading();

    try {
      const taskResponse$ = this.tasksService.getRule(this.integrator_id);
      const { data: dataTask } =
        ((await lastValueFrom(taskResponse$)) as any) ?? {};
      if (!dataTask) {
        this.notificationService.showResultDialog('No Task Found');
        return;
      }

      const { days_from_checkin } = resp;
      const taskRun$ = this.tasksService.runRule(this.integrator_id, {
        days_from_checkin,
      });
      const result = (await lastValueFrom(taskRun$)) as any;
      this.notificationService.showResultDialog(
        JSON.stringify(result, null, 2)
      );
      this.notificationService.showSuccessNotification();
    } catch (error) {
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }

  closeModal() {
    this.notificationService.closeTabsDialog();
  }

  async save() {
    if (!this.frecuency_hours) {
      this.FormsDataService.showFormErrors('Frecuency is required');
      return;
    }

    const existFilters = await this.verifyFilters({
      ...generalParams,
      integration_id: this.integrator_id!,
      deleted: false,
      size: 3000,
    });

    if (!existFilters) {
      const resp$ = this.dialog
        .open(ConfirmationDialogComponent, {
          data: 'There are not filters, are you sure save this task?',
        })
        .afterClosed();
      const resp = await lastValueFrom(resp$);

      if (!resp) return;
    }

    if (this.task) {
      this.updateTask();
    } else {
      this.createTask();
    }
  }
  async createTask() {
    try {
      this.notificationService.startLoading();
      const createTask$ = this.tasksService.createTask({
        ...this.task,
        frecuency_hours: +this.frecuency_hours,
        enabled: this.enabled,
        integration_id: this.integrator_id,
      });
      const { data } = (await lastValueFrom(createTask$)) as any;
      this.patchValues(data);
      this.notificationService.showSuccessNotification();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }
  async updateTask() {
    try {
      this.notificationService.startLoading();
      const updateTask$ = this.tasksService.updateTask(this.integrator_id, {
        ...this.task,
        frecuency_hours: this.frecuency_hours,
        enabled: this.enabled,
      });
      const { data } = (await lastValueFrom(updateTask$)) as any;
      this.patchValues(data);
      this.notificationService.showSuccessNotification();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }

  discardUnsavedChanges(): void {
    this.patchValues(this.task);
    this.tabsService.setUnsavedChanges(false);
  }
}
