import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { IntegrationsListComponent } from './pages/integrations-list/integrations-list.component';
import { authGuard } from './core/guards/auth.guard';

export const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'integration-list', component: IntegrationsListComponent, canActivate: [authGuard]},
    {path: '', redirectTo: 'integration-list', pathMatch: 'full'},
    {path: '**', redirectTo: 'integration-list', pathMatch: 'full'}
];
