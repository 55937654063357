<div class="p-3 bg-white rounded-lg shadow-md w-full max-w-4xl mx-auto mt-3 json-editor ">
    <div [formGroup]="mapperForm">
        <div>
            <div>
                <label class="block mb-2 text-sm font-medium text-gray-900">Name</label>
                <input formControlName="name" id="mapper-name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Name" required />
            </div>
        </div>
        <div class="mt-3">
            <div class="w-full min-h-72 flex flex-col justify-between h-full">
                <div class="flex-grow">
                    <div class="flex">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Fields</label>
                        <a href="https://docs.google.com/document/d/15m6Ntd0alRv3ot5cauG0raRy0T6ta_WFXoVasHGcYt4/edit"
                            class="pl-5 ml-auto text-blue-500 hover:underline" target="_blank"
                            rel="noopener noreferrer">Configuración de campos FTP por PMS</a>
                    </div>
                    <app-json-viewer #jsonViewer [jsonData]="jsonData"
                        (jsonValid)="onJsonValid($event)"></app-json-viewer>
                </div>
                <div class="mt-3 p-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                    role="alert">
                    <span class="font-medium">New: </span> No enviar un field con el campo 'id' porque en lugar de crear
                    uno
                    nuevo va a actualizar un field existente <br>
                    <span class="font-medium">Edit: </span> Si se quiere editar un Mapper enviar el campo 'id' en cada
                    field, en caso de que no se especifique, se creara uno nuevo asociado al Mapper actual.
                </div>

            </div>
        </div>
    </div>

    <mat-divider class="py-2"></mat-divider>

    <div class="mx-auto p-4 bg-white rounded-lg">
        <div class="mb-2 p-3 rounded-lg bg-gray-50">
            <p class="text-sm text-gray-500 !mb-0"><strong class="font-medium text-gray-800">Test mapper fields</strong>
            </p>
        </div>

        <form [formGroup]="fileForm" (ngSubmit)="onSubmitFile()">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                <div class="mb-4">
                    <label for="mapper-file" class="block text-gray-700">File</label>
                    <input id="mapper-file" type="file" (change)="onFileChange($event)"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2.5">
                </div>
            </div>

            <div class="flex justify-end">
                <button type="submit" id="mapper-test-file-btn"
                    class="my-2 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Test</button>
            </div>

        </form>
        <div class="w-full flex justify-end mt-4 pb-5">
            <button (click)="saveMapper()" type="submit" id="mapper-save-btn"
                class="my-1 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
        </div>
    </div>



    <mat-divider class="py-2"></mat-divider>

    <div class="mx-auto p-4 bg-white rounded-lg">
        <div class="mb-2 p-3 rounded-lg bg-gray-50">
            <p class="text-sm text-gray-500 !mb-0"><strong class="font-medium text-gray-800">Test regex</strong></p>
        </div>

        <form [formGroup]="regexForm" (ngSubmit)="onSubmitRegex()">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                <div>
                    <label class="block text-gray-700 pb-2">Operation</label>
                    <select formControlName="operation"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option [value]=""></option>
                        @for (operation of operations; track $index) {
                        <option [value]="operation">{{operation}}</option>
                        }
                    </select>
                </div>

                <div class="mb-4">
                    <label for="target" class="block text-gray-700">Target</label>
                    <input id="target" formControlName="target" type="text"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2.5"
                        placeholder="Enter target">
                </div>

                <div class="mb-4">
                    <label for="replacement" class="block text-gray-700">Replacement</label>
                    <input id="replacement" formControlName="replacement" type="text"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2.5"
                        placeholder="Enter replacement">
                </div>

                <div class="mb-4">
                    <label for="value" class="block text-gray-700">Value</label>
                    <input id="value" formControlName="value" type="text"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2.5"
                        placeholder="Enter value">
                </div>
            </div>

            <div class="flex justify-end">
                <button type="submit"
                    class="my-2 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Test</button>
            </div>

        </form>
    </div>


</div>