@if (loading) {
<app-loader></app-loader>
}

<div class="w-full bg-primary h-9 flex items-center justify-between">
    <label class="text-white pl-2 text-lg">Integration Manager</label>
    <mat-icon (click)="closeDialog()" class="text-white cursor-pointer mr-2">close</mat-icon>
</div>

<form [formGroup]="form" class="max-w-4xl mx-auto bg-white p-2 rounded-lg shadow-lg mt-2">
    <label class="ml-2 block mb-2 text-2xl font-medium text-gray-900">Contact {{action}}</label>
    <div class="grid gap-4 mb-6 md:grid-cols-3 p-3">
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Nombre</label>
            <input type="text" formControlName="first_name" id="contact-name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Nombre" />
        </div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Apellido</label>
            <input type="text" formControlName="last_name" id="contact-last-name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Apellido" />
        </div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Email</label>
            <input type="text" formControlName="email" id="contact-email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Email" />
        </div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Cargo</label>
            <input formControlName="company_position" id="contact-position"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Cargo" />
        </div>
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Telefono</label>
            <input type="text" formControlName="phone" id="contact-phone"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Telefono" />
        </div>

        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Celular</label>
            <input type="text" formControlName="mobile_phone" id="contact-cellphone"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Celular" />
        </div>

        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Skype</label>
            <input type="text" formControlName="skype" id="contact-skipe"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Skype" />
        </div>

        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900">Prioridad</label>
            <input type="number" formControlName="priority" id="contact-priority"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Prioridad" />
        </div>

        <div class="md:col-span-3">
            <label class="block mb-2 text-sm font-medium text-gray-900">Notas</label>
            <textarea formControlName="notes" id="contact-notes"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                rows="4" placeholder="Notas"></textarea>
        </div>
    </div>

    <div class="w-full flex justify-end">
        <button type="submit" (click)="onSubmit()" id="contact-save-btn"
            class="mx-2 text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
    </div>
</form>