// import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'integrations-manager-v1';
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const themeClass = `theme-${environment.theme}`;
    this.renderer.addClass(document.body, themeClass);
  }
}
