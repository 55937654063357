import { Component, Input, effect, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FilterGroupService } from '../../core/services/filter-group.service';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FilterDetailsComponent } from '../filter-details/filter-details.component';
import { NotificationService } from '../../core/services/notification.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatInputModule, MatTableModule],
  providers: [],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
})
export class FiltersComponent {
  @Input() integrator_id: number | null | undefined = null;
  filterGroupService = inject(FilterGroupService);
  notificationService = inject(NotificationService);
  integrationProfileDataService = inject(IntegrationProfileDataService);
  readonly filterDetailsDialogConfiguration = {
    disableClose: true,
    panelClass: 'dialog-content-container',
  };
  readonly configParams = {
    page: 0,
    size: 3000,
    sort_dir: 'ASC',
    sort: 'id',
  };
  dataSource = new MatTableDataSource();
  displayedColumns: any = [
    'actions',
    'id',
    'name',
    'integration_id',
    'exclude',
    'deleted',
  ];
  input: any;
  constructor(private dialog: MatDialog) {
    effect(() => {
      this.integrator_id =
        this.integrationProfileDataService.selectedIntegration()?.id ??
        this.integrator_id;
    });
  }

  ngOnInit(): void {
    if (this.integrator_id) {
      this.updateFilters({
        ...this.configParams,
        deleted: false,
        integration_id: this.integrator_id,
      });
    }
  }

  private async updateFilters(params: {
    page: number;
    size: number;
    sort_dir: string;
    sort: string;
    deleted: boolean;
    integration_id?: number;
  }) {
    this.notificationService.startLoading();
    const filterGroups$ = this.filterGroupService.searchProfileGroup(params);
    const { data } = (await lastValueFrom(filterGroups$)) as any;
    this.dataSource.data = data;
    this.notificationService.stopLoading();
  }

  edit(filter: any, event?: any) {
    const target = event?.target as HTMLElement;
    if (target?.closest('.action-button')) {
      return;
    }

    this.dialog
      .open(FilterDetailsComponent, {
        data: {
          filter,
          integration_id: this.integrator_id,
          action: 'Update',
        },
        ...this.filterDetailsDialogConfiguration,
      })
      .afterClosed()
      .subscribe(() => {
        if (this.integrator_id)
          this.updateFilters({
            ...this.configParams,
            deleted: false,
            integration_id: this.integrator_id,
          });
      });
  }
  openFilterDetails() {
    this.dialog
      .open(FilterDetailsComponent, {
        data: {
          integration_id: this.integrator_id,
          action: 'Create',
        },
        ...this.filterDetailsDialogConfiguration,
      })
      .afterClosed()
      .subscribe(() => {
        if (this.integrator_id)
          this.updateFilters({
            ...this.configParams,
            deleted: false,
            integration_id: this.integrator_id,
          });
      });
  }
  async deleteElement(filter: any) {
    const resp$ = this.dialog
      .open(ConfirmationDialogComponent, {
        data: 'Are you sure you want to delete this?',
      })
      .afterClosed();
    const resp = await lastValueFrom(resp$);

    if (!resp || !this.integrator_id) return;

    try {
      this.notificationService.startLoading();
      const deleteFilter$ = this.filterGroupService.deleteFilterGroup(
        filter.id
      );
      const { data } = (await lastValueFrom(deleteFilter$)) as any;
      await this.updateFilters({
        ...this.configParams,
        deleted: false,
        integration_id: this.integrator_id,
      });
      this.notificationService.showSuccessNotification();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.notificationService.stopLoading();
    }
  }
}
