import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IResponseString, IResponseMapper } from '../models/IResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MappersService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getIntegrationMappers(params: any): Observable<IResponseMapper> {
    return this.http.get<IResponseMapper>(`${this.url}/integration-mappers`, {
      params,
    });
  }

  getMapperSeparators(): Observable<IResponseString> {
    return this.http.get<IResponseString>(
      `${this.url}/integration-mappers/mapper-separators`
    );
  }

  getMapperFileExtension(): Observable<IResponseString> {
    return this.http.get<IResponseString>(
      `${this.url}/integration-mappers/file-extensions`
    );
  }

  getMapperOperations(): Observable<IResponseString> {
    return this.http.get<IResponseString>(
      `${this.url}/integration-mappers/mapper-operations`
    );
  }

  updateMultiMapper(mapper_id: any, payload: any) {
    return this.http.put(
      `${this.url}/integration-mappers/${mapper_id}`,
      payload
    );
  }

  createMapper(payload: any) {
    return this.http.post(`${this.url}/integration-mappers/`, payload);
  }

  testRegex(payload: any) {
    return this.http.post(
      `${this.url}/integration-mappers/fields/regex`,
      payload
    );
  }

  testMapperFields(payload: any) {
    let formData = new FormData();

    Object.keys(payload).forEach((key) => {
      const value = payload[key];

      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    return this.http.post(
      `${this.url}/integration-mappers/fields/test`,
      formData
    );
  }
}
