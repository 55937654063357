<div class="w-full bg-primary h-9 flex items-center justify-between">
    <label class="text-white pl-2 text-lg">Integration Manager</label>
    <mat-icon (click)="closeDialog()" class="text-white cursor-pointer mr-2">close</mat-icon>
</div>

<div class="w-full p-3  flex flex-col justify-between h-full">
    <div class="flex-grow">
        <label class="block mb-2 text-xl font-medium text-gray-900 mr-1">{{title}}</label>

        <form [formGroup]="form">
            <div class="flex items-center justify-between p-1 w-full">
                <label class="text-sm font-medium text-gray-900 mr-1">Days From Checkin</label>
                <mat-icon
                    matTooltip="Específica desde cuantos días hacia atrás se van a consultar huéspedes en esta ejecución (Solo se aplica a ejecuciones de WS). En caso de dejar este campo vacío, se va a utilizar el valor por defecto del tipo de integración."
                    class="cursor-pointer text-primary text-xl">info</mat-icon>
            </div>

            <input id="inputField" type="number" formControlName="inputField" id="input_days_checkin"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Days From Checkin" />
        </form>
    </div>
    <div class="w-full flex justify-end mt-4">
        <button type="submit" (click)="closeConfirmedDialog()" id="run-checkin-btn"
            class="text-white bg-green-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Run</button>
    </div>
</div>