import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  ViewChild,
  effect,
  inject,
} from '@angular/core';
import {
  MatTabChangeEvent,
  MatTabGroup,
  MatTabsModule,
} from '@angular/material/tabs';
import { IntegrationComponent } from '../integration/integration.component';
import { MappersComponent } from '../mappers/mappers.component';
import { TasksComponent } from '../tasks/tasks.component';
import { UploaderComponent } from '../uploader/uploader.component';
import { FiltersComponent } from '../filters/filters.component';
import { ContactsComponent } from '../contacts/contacts.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NotificationService } from '../../core/services/notification.service';
import { IIntegration } from '../../core/models/IIntegration';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';
import { TabsService } from '../../core/services/tabs.service';
import { TabChangeResult } from '../../core/models/TabChangeResult.enum';
import { DiscartableDataTab } from '../../core/models/DiscartableDataTab';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-integration-details',
  standalone: true,
  imports: [
    MatTabsModule,
    MatIconModule,
    IntegrationComponent,
    MappersComponent,
    TasksComponent,
    UploaderComponent,
    FiltersComponent,
    ContactsComponent,
    LoaderComponent,
  ],
  templateUrl: './integration-details.component.html',
  styleUrl: './integration-details.component.scss',
})
export class IntegrationDetailsComponent {
  integration: IIntegration | null;
  notificationService = inject(NotificationService);
  integrationProfileDataService = inject(IntegrationProfileDataService);
  tabsService = inject(TabsService);
  keydownSubscription: Subscription | null = null;

  loading: boolean = false;
  @ViewChild('tabGroup', { static: true }) tabGroup!: MatTabGroup;
  @ViewChild('Integration', { static: true })
  integrationComponent!: IntegrationComponent;
  @ViewChild('Mappers', { static: true })
  mappersComponent!: MappersComponent;
  @ViewChild('Task', { static: true })
  tasksComponent!: TasksComponent;
  disabledTabs: boolean = false;

  mapTabsDiscartable: Map<string, DiscartableDataTab> = new Map<
    string,
    DiscartableDataTab
  >();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IntegrationDetailsComponent>,
    private elementRef: ElementRef
  ) {
    this.integration = this.data;
    this.notificationService.openTabsDialog();
    effect(() => {
      if (!this.notificationService.isTabDialogOpen()) {
        this.dialogRef.close();
      }

      this.loading = this.notificationService.isLoading();

      this.disabledTabs =
        !!!this.integrationProfileDataService.selectedIntegration();
    });

    effect(() => {
      this.integration =
        this.integrationProfileDataService.selectedIntegration() ?? this.data;
    });
  }

  ngOnInit(): void {
    this.initializeTabMap();
    this.tabsService.setMatTabGroup(this.tabGroup);
    this.keydownSubscription = this.dialogRef
      .keydownEvents()
      .subscribe((event) => {
        if (event.key === 'Escape') {
          this.closeDialog();
        }
      });
  }

  ngAfterViewInit() {
    const dialogHeaderHeight =
      this.elementRef.nativeElement.querySelector(
        '.dialog-header'
      ).offsetHeight;
    const tabHeader = this.elementRef.nativeElement.querySelector(
      '.mat-mdc-tab-header'
    );
    tabHeader.style.top = dialogHeaderHeight + 'px';
  }

  initializeTabMap() {
    this.mapTabsDiscartable.set('Integration', this.integrationComponent);
    this.mapTabsDiscartable.set('Mappers', this.mappersComponent);
    this.mapTabsDiscartable.set('Task', this.tasksComponent);
  }

  ngOnDestroy(): void {
    this.integrationProfileDataService.clearSelectedIntegration();
    this.tabsService.resetIndexes();
    this.keydownSubscription?.unsubscribe();
  }

  async closeDialog() {
    const tab = this.tabsService.getActiveTab();
    const tabDisc = this.mapTabsDiscartable.get(tab.textLabel);
    tabDisc?.hasFormChanged();
    this.notificationService.closeTabsDialog();
  }

  async onTabChange($event: MatTabChangeEvent) {
    const prevTab = this.tabsService.getPreviuosTab();

    const tab = this.mapTabsDiscartable.get(prevTab.textLabel);
    tab?.hasFormChanged();

    const result: TabChangeResult = await this.tabsService.onTabChange($event);

    if (result === TabChangeResult.DiscardChanges) {
      tab?.discardUnsavedChanges();
      return;
    }

    if (result === TabChangeResult.KeepEditing) {
      const previousTabIndex = this.tabsService.getTabIndex(prevTab);
      this.tabsService.setTabByIndex(previousTabIndex);
      return;
    }
  }
}
