import {
  HttpEvent,
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { environment } from '../../../environments/environment';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const notificationService = inject(NotificationService);
  let authReq = req;
  const token = authService.token;
  const apiKey = authService.apiKeyHeader;
  const apiKeyFS = environment.apiKeyFid;

  const url = new URL(req.url);
  let headers: any;

  if (url.host !== new URL(environment.apiUrl).host) {
    headers = req.headers.set('x-api-key', apiKeyFS);
    headers = headers.set('Content-Type', 'application/json');
  } else {
    headers = req.headers.set('x-api-key', apiKey);
  }

  if (token) {
    headers = headers.set('Authorization', `Bearer ${token}`);
  }

  authReq = req.clone({ headers });

  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        const refreshToken$ = authService.refreshToken();
        if (!refreshToken$) {
          authService.logout();
          return throwError(() => new Error('No refresh token available'));
        }

        return refreshToken$.pipe(
          switchMap((response: any) => {
            headers = headers.set('Authorization', `Bearer ${response.token}`);
            authReq = req.clone({ headers });
            return next(authReq);
          }),
          catchError((err) => {
            authService.logout();
            return throwError(() => new Error(err.message));
          })
        );
      } else {
        notificationService.showErrorDialog(
          JSON.stringify(error.error, null, 2)
        );
        return throwError(() => new Error(error.message));
      }
    })
  );
};
