import { Component, Inject, Input, ViewChild, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  MatDatepickerIntl,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { NotificationService } from '../../core/services/notification.service';
import { IntegrationProfileService } from '../../core/services/integration-profile.service';
import {
  Observable,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  lastValueFrom,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { IIntegrationType } from '../../core/models/IIntegrationType';
import { MappersService } from '../../core/services/mappers.service';
import { IIntegration, IIntegrationPms } from '../../core/models/IIntegration';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { IntegrationProfileDataService } from '../../core/services/Integration-profile-data.service';
import { CustomerService } from '../../core/services/customer.service';
import { CustomerFidelityService } from '../../core/services/customer-fidelity.service';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DiscartableDataTab } from '../../core/models/DiscartableDataTab';
import { TabsService } from '../../core/services/tabs.service';
import { MatIconModule } from '@angular/material/icon';
import { FormsDataService } from '../../core/services/forms-data.service';
import { JsonViewerComponent } from '../../shared/components/json-viewer/json-viewer.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    LoaderComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    JsonViewerComponent,
    MatIconModule,
    MatTooltipModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-MX' }],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss',
})
export class IntegrationComponent implements DiscartableDataTab {
  integrationProfileDataService = inject(IntegrationProfileDataService);
  integrationPmss: IIntegrationPms[] = [];
  forced_integration_qualitys: any;
  @Input() integratorData: IIntegration | null = null;
  notificationService = inject(NotificationService);
  integrationProfileService = inject(IntegrationProfileService);
  mappersService = inject(MappersService);
  customerService = inject(CustomerService);
  customerFidelityService = inject(CustomerFidelityService);
  tabsService = inject(TabsService);
  formsDataService = inject(FormsDataService);
  form: FormGroup;
  integration_types: IIntegrationType[] = [];
  separators: string[] = [];
  fileExtensions: string[] = [];
  fileCharsets: string[] = [];
  loading: boolean = false;
  optionsType: any[] = [];
  optionsPms: any[] = [];
  filteredOptionsCustomer: Observable<any[]> | undefined;
  filteredOptionsType: Observable<any[]> | undefined;
  filteredOptiomsPms: Observable<any[]> | undefined;
  updatedForm: IIntegration | null = null;
  update: boolean = false;
  @ViewChild('jsonViewer') jsonViewer!: JsonViewerComponent;
  invalidJson: boolean = false;
  jsonData: any;
  private subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _intl: MatDatepickerIntl
  ) {
    // set date DD/MM/YYYY
    this._locale = 'es-MX';
    this.dateAdapter.setLocale(this._locale);
    this._intl.changes.next();
    this.form = this.formBuilder.group({
      id: [''],
      forced_integration_quality: [''],
      url: [''],
      user: [''],
      password: [''],
      ftp_path: [''],
      mapper_separator: [''],
      mapper_file_extension: [''],
      obtain_data: [''],
      disable_filter_notification: [false],
      customer: ['', Validators.required],
      type: [''],
      pms: [''],
      integration_type: ['', Validators.required],
      integration_pms: [''],
      flag_filter_domains: [false],
      survey_types_default: ['1,2', Validators.required],
      flag_alerts_active: [true],
      alert_days: [2, Validators.required],
      seasonal_flag: [null],
      open_date: [''],
      close_date: [''],
      connection_type: [''],
      excluded_domains: [''],
      notes: [''],
      file_name: [''],
      file_charset: [''],
      email_filter: [''],
      email_subject: [''],
      exclude_last_file_read: [true],
      file_first_row: [],
    });

    this.filteredOptionsCustomer = this.form
      ?.get('customer')
      ?.valueChanges.pipe(
        debounceTime(100),
        startWith(''),
        distinctUntilChanged(),
        filter((value) => value.length >= 3),
        switchMap((value) => this.searchCustomer(value)),
        catchError(() => of([]))
      );
  }

  async ngOnInit() {
    await this.loadData();

    const seasonalSubscription = this.form
      ?.get('seasonal_flag')
      ?.valueChanges.subscribe((seasonal) => {
        if (seasonal) {
          this.form?.get('open_date')?.enable();
          this.form?.get('close_date')?.enable();
          this.form?.get('open_date')?.setValidators([Validators.required]);
          this.form?.get('close_date')?.setValidators([Validators.required]);
        } else {
          this.form?.get('open_date')?.clearValidators();
          this.form?.get('close_date')?.clearValidators();
          this.form?.get('open_date')?.disable();
          this.form?.get('close_date')?.disable();
        }

        this.form?.get('open_date')?.updateValueAndValidity();
        this.form?.get('close_date')?.updateValueAndValidity();
      });

    this.form?.get('seasonal_flag')?.setValue(false);

    const typeSuscription = this.form
      .get('type')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.form
            .get('integration_pms')
            ?.setValidators([Validators.required]);
        } else {
          this.form.get('integration_pms')?.clearValidators();
        }
        this.form.get('integration_pms')?.updateValueAndValidity();
      });

    this.subscriptions.add(seasonalSubscription);
    this.subscriptions.add(typeSuscription);

    this.update = !!this.integratorData;
    if (this.integratorData) this.patchValues(this.integratorData);
    else this.integratorData = this.convertValues();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private _filter(value: any, options: any[]): any[] | undefined {
    if (!value) return;
    const stringValue = JSON.stringify(value);
    if (stringValue === '{}') value = '';

    const filterValue = value.name?.toLowerCase() ?? value?.toLowerCase();

    const result = options.filter(
      (option) => option.name?.toLowerCase().includes(filterValue) ?? []
    );

    return result;
  }

  private async loadData() {
    this.loading = true;
    try {
      const integrationTypes$ =
        this.integrationProfileService.getIntegrationTypes();
      const fileExtensions$ = this.mappersService.getMapperFileExtension();
      const separators$ = this.mappersService.getMapperSeparators();
      const fileCharsets$ = this.integrationProfileService.getFileCharsets();
      const integrationPmss$ =
        this.integrationProfileService.getIntegrationPmss();
      const integrationQualities$ =
        this.integrationProfileService.getIntegrationQualities();
      const integration$ = this.integratorData?.id
        ? this.integrationProfileService.getIntegrationProfile(
            this.integratorData.id
          )
        : of(null);

      const observers = {
        next: (results: any) => {
          this.optionsType = results.integrationTypes$.data;
          this.fileExtensions = results.fileExtensions$.data;
          this.fileCharsets = results.fileCharsets$.data;
          this.separators = results.separators$.data;
          this.integrationPmss = results.integrationPmss$.data;
          this.optionsPms = results.integrationPmss$.data;
          this.forced_integration_qualitys = results.integrationQualities$.data;

          if (results.integration$?.data) {
            const integration = results.integration$.data as IIntegration;
            this.jsonData = integration.obtain_data;
            this.integratorData = integration;
            this.patchValues(integration);
            this.integrationProfileDataService.setSelectedIntegration(
              integration
            );
          }

          this.filteredOptionsType = this.form.get('type')?.valueChanges.pipe(
            startWith(''),
            map((value) => {
              const filtered = this._filter(value || '', this.optionsType);
              return filtered || this.optionsType;
            })
          );

          this.filteredOptiomsPms = this.form
            .get('integration_pms')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) => {
                const filtered = this._filter(value || '', this.optionsPms);
                return filtered || this.optionsPms;
              })
            );

          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error fetching data', error);
          this.loading = false;
        },
      };

      forkJoin({
        integrationTypes$: integrationTypes$,
        fileExtensions$: fileExtensions$,
        fileCharsets$: fileCharsets$,
        separators$: separators$,
        integrationPmss$: integrationPmss$,
        integrationQualities$: integrationQualities$,
        integration$: integration$,
      }).subscribe(observers);
    } catch (error) {
      console.error('Error fetching integration types', error);
    } finally {
    }
  }

  closeDialog() {
    this.notificationService.closeTabsDialog();
  }

  formatDate(date: Date): string | null {
    if (!date) return null;

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  hasFormChanged(): boolean {
    this.updateJson();
    if (!this.integratorData) return true;
    const payload = this.convertValues();
    if (!payload) {
      this.tabsService.setUnsavedChanges(true);
      return true;
    }
    payload.close_date = this.formatDate(payload?.close_date);
    payload.open_date = this.formatDate(payload?.open_date);
    return this.tabsService.hasOriginalValueChanged(this.integratorData, {
      ...this.integratorData,
      ...payload,
    });
  }

  updateJson() {
    this.jsonViewer.validateJson();
  }

  onJsonValid(updatedJson: any) {
    if (updatedJson === 'jsonError') {
      this.invalidJson = true;
    } else {
      this.invalidJson = false;
    }
    this.form
      .get('obtain_data')
      ?.setValue(JSON.stringify(updatedJson, null, 2));
  }

  async searchCustomer(query: any) {
    const customersFiltered$ = this.customerFidelityService.findByWord({
      query,
      type: 'client',
      show_privates: false,
    });
    const data = lastValueFrom(customersFiltered$) as any;
    return data as any[];
  }

  displayFn(customer: any): string {
    return customer && customer.name ? `${customer.id} - ${customer.name}` : '';
  }

  convertTimestampToDate(timestamp: number | null): Date | null {
    const date = timestamp ? new Date(timestamp) : null;
    if (!date) return null;
    const gmtDate = new Date(date.toUTCString());
    return gmtDate;
  }

  patchValues(data: IIntegration) {
    const open_date = this.convertToDate(data.open_date);
    const close_date = this.convertToDate(data.close_date);
    if (data?.obtain_data) this.jsonData = [...data.obtain_data];
    else this.jsonViewer.clearJsonField();

    this.form.patchValue({
      ...data,
      obtain_data: data.obtain_data
        ? JSON.stringify(data.obtain_data, null, 2)
        : null,
      open_date,
      close_date,
      type: {
        ...data.type,
      },
      customer: {
        ...data.customer,
      },
    });
    this.checkAndSetEmpty('type');
  }

  convertToDate(dateString: string): Date | null {
    if (!dateString) return null;

    return new Date(`${dateString}T00:00:00`);
  }

  replaceEmptyStringsWithNull(obj: any, excludeProps: string[] = []): any {
    if (obj !== null && typeof obj === 'object') {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          obj[i] = this.replaceEmptyStringsWithNull(obj[i], excludeProps);
        }
      } else {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (obj[key] === '' && !excludeProps.includes(key)) {
              obj[key] = null;
            } else if (typeof obj[key] === 'object') {
              this.replaceEmptyStringsWithNull(obj[key], excludeProps);
            }
          }
        }
      }
    }
    return obj;
  }

  private checkAndSetEmpty(controlName: string): void {
    const control = this.form.get(controlName);
    if (control) {
      const stringValue = JSON.stringify(control.value);

      if (stringValue === '{}') {
        control.setValue('');
      }
    }
  }

  async onSubmit() {
    this.updateJson();
    if (this.invalidJson) return;
    if (this.form.valid) {
      const payload: IIntegration | null = this.convertValues();
      this.replaceEmptyStringsWithNull(payload, ['obtain_data']);

      this.update
        ? await this.updateIntegrationProfile(payload)
        : await this.createIntegrationProfile({
            ...payload,
          });
    } else {
      this.formsDataService.showFormErrors(this.form);
    }
  }

  private convertValues() {
    try {
      const openDateValue = this.form.get('open_date')?.value;
      const closeDateValue = this.form.get('close_date')?.value;
      this.checkAndSetEmpty('customer');
      this.checkAndSetEmpty('type');
      this.checkAndSetEmpty('integration_pms');

      const customer: any = this.form.get('customer')?.value;
      const customerCleared = this.clearFields(customer);

      const payload: IIntegration = {
        ...this.form.getRawValue(),
        type:
          this.form.get('type')?.value != ''
            ? this.form.get('type')?.value
            : null,
        integration_pms:
          this.form.get('integration_pms')?.value != ''
            ? this.form.get('integration_pms')?.value
            : null,
        customer: customerCleared ? customerCleared : null,
        open_date: openDateValue ? new Date(openDateValue) : null,
        close_date: closeDateValue ? new Date(closeDateValue) : null,
        obtain_data:
          this.form.get('obtain_data')?.value != ''
            ? JSON.parse(this.form.get('obtain_data')?.value)
            : null,
        mapper_separator: this.form.get('mapper_separator')?.value || null,
        mapper_file_extension:
          this.form.get('mapper_file_extension')?.value || null,
      };
      return payload;
    } catch (error) {
      this.notificationService.showErrorDialog(error as string);
      return null;
    }
  }

  private clearFields(customer: any) {
    if (!customer) return null;
    delete customer?.flags;
    delete customer?.online;
    delete customer?.cases;
    delete customer?.semantic;
    delete customer?.url_logo;
    delete customer?.voices;
    delete customer?.smart_replies;
    delete customer?.country_code;

    return customer;
  }

  private async updateIntegrationProfile(payload: IIntegration | null) {
    if (!payload) return;
    this.loading = true;
    try {
      const integrationUpdateREsponse$ =
        this.integrationProfileService.updateIntegrationProfile(
          this.integratorData?.id,
          payload
        );
      const { data } = (await lastValueFrom(integrationUpdateREsponse$)) as any;
      this.integratorData = data as IIntegration;
      this.patchValues(data);
      this.tabsService.setUnsavedChanges(false);
      this.integrationProfileDataService.setSelectedIntegration(data);
      this.integrationProfileDataService.updateIntegration(data);
      this.notificationService.showSuccessNotification();
    } catch (error) {
      console.log(error);
      this.notificationService.showErrorNotification();
    } finally {
      this.loading = false;
    }
  }

  async createIntegrationProfile(payload: IIntegration) {
    if (!payload) return;
    delete payload.id;
    this.loading = true;
    try {
      const integrationUpdateREsponse$ =
        this.integrationProfileService.createIntegrationProfile(payload);
      const { data } = (await lastValueFrom(integrationUpdateREsponse$)) as any;
      this.integratorData = data;
      this.patchValues(data);
      this.tabsService.setUnsavedChanges(false);
      this.integrationProfileDataService.setSelectedIntegration(data);
      this.integrationProfileDataService.createIntegration(data);
      this.notificationService.showSuccessNotification();
      this.update = true;
    } catch (error) {
      this.notificationService.showErrorDialog(error as string);
    } finally {
      this.loading = false;
    }
  }

  public discardUnsavedChanges() {
    if (!this.integratorData) return;
    this.patchValues(this.integratorData);
  }
}
