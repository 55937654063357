import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getUploader(params: any) {
    return this.http.get(`${this.url}/uploaders/configs`, { params });
  }

  createUploader(body: any) {
    let formData = new FormData();
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return this.http.post(`${this.url}/uploaders/configs`, formData);
  }
}
