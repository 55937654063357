import { Component, Inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterLink, FormsModule, MatIconModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  loginError: boolean = false;
  loading: boolean = false;
  constructor(private router: Router, private authService: AuthService) {}

  onSubmit(form: NgForm): void {
    if (!form.valid) return;

    this.loginError = false;
    this.loading = true;
    this.authService
      .login(form.controls['email'].value, form.controls['password'].value)
      .subscribe({
        next: () => {
          if (this.authService.isLoggedIn) {
            this.router.navigate(['/integration-list']);
          }
          this.loading = false;
        },
        error: (error) => {
          console.error('Login failed', error);
          this.loginError = true;
          this.authService.logout();
          this.loading = false;
        },
      });
  }
}
