<div class="flex w-full max-w-4xl mx-auto mt-3">
  <div class="w-full bg-white border border-gray-200 px-3 overflow-auto">

    <div class="w-full flex justify-end pt-2">
      <button type="button" (click)="openFilterDetails()" id="create-filter-btn"
        class="mt-4 md:mt-0 md:ml-4 p-3 w-12 h-12 text-sm font-medium text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full flex items-center justify-center">
        <mat-icon>add</mat-icon>
      </button>
    </div>



    <div class="overflow-y-auto">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full my-4" id="filters-table">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="min-w-3.5">
            <div class="flex">
              <button class="mx-1 action-button" mat-icon-button (click)="edit(element)">
                <mat-icon class="text-primary">edit</mat-icon>
              </button>
              <button class="mx-1 action-button" mat-icon-button (click)="deleteElement(element)"
                id="filter-delete-btn">
                <mat-icon class="text-red-500">delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="integration_id">
          <th mat-header-cell *matHeaderCellDef> Integration id </th>
          <td mat-cell *matCellDef="let element"> {{element.integration_id}} </td>
        </ng-container>
        <ng-container matColumnDef="exclude">
          <th mat-header-cell *matHeaderCellDef> Exclude </th>
          <td mat-cell *matCellDef="let element"> {{element.exclude}} </td>
        </ng-container>
        <ng-container matColumnDef="deleted">
          <th mat-header-cell *matHeaderCellDef> Deleted </th>
          <td mat-cell *matCellDef="let element"> {{element.deleted}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="edit(row, $event)"></tr>
      </table>
    </div>

  </div>
</div>