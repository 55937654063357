import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormsDataService } from '../../../core/services/forms-data.service';

@Component({
  selector: 'app-input-dialog-confirmation',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, ReactiveFormsModule],
  templateUrl: './input-dialog-confirmation.component.html',
  styleUrl: './input-dialog-confirmation.component.scss',
})
export class InputDialogConfirmationComponent {
  form: any;
  title: any = 'Run Task';
  FormsDataService = inject(FormsDataService);
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      inputField: ['', [Validators.min(0)]],
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  closeConfirmedDialog() {
    if (!this.form.valid) {
      this.FormsDataService.showFormErrors(this.form);
      return;
    }
    this.dialogRef.close({
      days_from_checkin: this.form.get('inputField')?.value,
      result: true,
    });
  }
}
