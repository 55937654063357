import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { lastValueFrom } from 'rxjs';
import { TabChangeResult } from '../models/TabChangeResult.enum';

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  private _matTabGroup!: MatTabGroup;
  changesPendingSave: boolean = false;
  internalChange: boolean = false;
  previousTabIndex: number = 0;
  activeTabIndex: number = 0;

  constructor(private dialog: MatDialog) {}

  public setMatTabGroup(group: MatTabGroup) {
    this._matTabGroup = group;
  }

  get hasUnsavedChanges(): boolean {
    return this.changesPendingSave;
  }

  setUnsavedChanges(changesPendingSave: boolean) {
    this.changesPendingSave = changesPendingSave;
  }

  clearUnsavedChanges() {
    this.changesPendingSave = false;
  }

  resetIndexes() {
    this.activeTabIndex = 0;
    this.previousTabIndex = 0;
  }

  async onTabChange(event: MatTabChangeEvent): Promise<TabChangeResult> {
    this.previousTabIndex = this.activeTabIndex = event.index;
    if (this.internalChange) {
      this.internalChange = false;
      return TabChangeResult.InternalChange;
    }

    if (!this.changesPendingSave) {
      return TabChangeResult.NotUnsavedData;
    }

    const resp$ = this.dialog
      .open(ConfirmationDialogComponent, {
        data: 'There are changes without saving, are you sure you want to discard them?',
      })
      .afterClosed();
    const resp = await lastValueFrom(resp$);

    // keep editing
    if (!resp) {
      this.internalChange = true;
      return TabChangeResult.KeepEditing;
    }

    // discard changes
    this.previousTabIndex = this.activeTabIndex = event.index;
    this.clearUnsavedChanges();
    return TabChangeResult.DiscardChanges;
  }

  getPreviuosTabLabel() {
    const tab = this._matTabGroup._tabs.toArray()[this.previousTabIndex];
    return tab.textLabel;
  }

  getPreviuosTab(): MatTab {
    const tab = this._matTabGroup._tabs.toArray()[this.previousTabIndex];
    return tab;
  }

  getTabNameByIndex(index: number) {
    const tab = this._matTabGroup._tabs.toArray()[index];
    return tab.textLabel || null;
  }
  getTabIndex(tab: MatTab): number | null {
    const tabArray = this._matTabGroup._tabs.toArray();
    for (let i = 0; i < tabArray.length; i++) {
      if (tabArray[i] === tab) {
        return i;
      }
    }
    return null;
  }

  cancelTabSwitch() {
    this._matTabGroup.selectedIndex = this.previousTabIndex;
  }

  setTabByIndex(index: number | null) {
    if (index === null) return;
    this._matTabGroup.selectedIndex = index;
  }

  getActiveTab() {
    const tab = this._matTabGroup._tabs.toArray()[this.activeTabIndex];
    return tab;
  }

  hasOriginalValueChanged(initialData: any, actualData: any): boolean {
    const strData = this.sortedStringify(initialData);
    const strPayload = this.sortedStringify(actualData);
    const result = strData !== strPayload;
    this.changesPendingSave = result;
    return result;
  }

  sortedStringify(obj: any): string {
    const allKeys: string[] = [];
    JSON.stringify(obj, (key, value) => {
      allKeys.push(key);
      return value;
    });
    allKeys.sort();

    return JSON.stringify(obj, allKeys);
  }
}
