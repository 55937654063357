<div class="w-full bg-primary h-9 flex items-center justify-between">
    <label class="text-white pl-2 text-lg">Integration Manager</label>
    <mat-icon (click)="closeDialog()" class="text-white cursor-pointer mr-2">close</mat-icon>
</div>

<div class="w-full p-3  flex flex-col justify-between h-full">
    <div class="flex-grow">
        <label class="block mb-2 text-sm font-medium text-gray-900">{{title}}</label>
        <app-json-viewer #jsonViewer [height]="400" [hideButton]="true" [isEditable]="false"></app-json-viewer>
    </div>
    <div class="w-full flex justify-end mt-4">
        <button type="submit" (click)="closeDialog()" id="close-message-button"
            class="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">close</button>
    </div>
</div>